import { Component, Input, OnInit } from '@angular/core';
import { OrganizationAffiliationsGroupedByLocation } from '../../../../_core/api/models/organization-affiliations-grouped-by-location';
import { PractitionerRolesGroupedByLocation } from '../../../../_core/api/models/practitioner-roles-grouped-by-location';
import {
  processedTelecom,
  SearchResultCardExpandTab2Service
} from '../../../../_core/services/searchResultCard/search-result-card-expand-tab2.service';
@Component({
  selector: 'app-search-result-card-expand-tab2',
  templateUrl: './search-result-card-expand-tab2.component.html',
  styleUrls: ['./search-result-card-expand-tab2.component.scss'],
})
export class SearchResultCardExpandTab2Component implements OnInit {
  i: number = 0;
  item: any;
  locationData: {
    telecomData: processedTelecom;
    root: OrganizationAffiliationsGroupedByLocation | PractitionerRolesGroupedByLocation
  }[];
  constructor(
    public searchResultCardExpandTab2Service: SearchResultCardExpandTab2Service
  ) {}
  currentIndex = 0;
  @Input() relationsGroupedByLocation!:
    | OrganizationAffiliationsGroupedByLocation[]
    | PractitionerRolesGroupedByLocation[];

  ngOnInit() {
    this.relationsGroupedByLocation = (this.relationsGroupedByLocation as any[]).filter((relation) => {
      return !relation.location?.meta!["suppress"]
    })
    this.getProcessedData();
    this.i = 0;
    this.item = this.locationData[this.i]
  }

  getProcessedData() {
    this.locationData =  this.relationsGroupedByLocation.map((root) => ({
      root,
      telecomData: this.searchResultCardExpandTab2Service.processTelecom(
        root.location!.telecom!
      ),
    }));
  }

  currentActive(value: number) {
    this.currentIndex = value;
    this.i = value;
    this.item = this.locationData[this.i]
  }
  getFormattedPhoneFax(value: string): string {
    // Check if the value is in the format (123) 456-7896 or 123-456-7896
    const regex = /\(?(\d{3})?\)?[-.\s]?(\d{3})?[-.\s]?(\d{4})?/;
    const match = value.match(regex);

    if (match) {
      return `(${match[1] || 'XXX'}) ${match[2] || 'XXX'}-${match[3] || 'XXXX'}`;
    }

    return value;
  }

  capitalizeEachWord(text: string): string {
    if (!text) return ''; // Handle undefined or null cases
    return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  // Method to format the URL by removing http:// or https://
  formatUrl(url: string): string {
    return url.replace(/^(https?:\/\/)/, '');
  }
}
