<div class="carousel-container">
  <div class="carousel-window" #carouselWindow>
    <ng-content></ng-content>
  </div>
  <div class="prev-next-block flex">
    <button (click)="prev()" class="prev" [class.disabled]="currentIndex <= 0" tabindex="0">
      &#8592;
    </button>
    <div class="carousel-dots flex" *ngIf="showDots">
      <span
        class="dot"
        *ngFor="let item of items; let i = index"
        [class.active]="i === currentIndex"
        (click)="goToSlide(i)"
      ></span>
    </div>
    <div class="slide-count flex" *ngIf="hideSlideCount">
      <div class="total-count">
        Item: {{ currentIndex + 1 }} of {{ items.length }}
      </div>
    </div>
    <button
      (click)="next()"
      class="next"
      [class.disabled]="currentIndex >= items.length - 1"
      tabindex="0"
    >
      &#8594;
    </button>
  </div>
</div>
