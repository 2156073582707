// google-api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { loadGoogleMaps, GoogleMapsLibraries } from '../loadGoogleApi';
import { CareFindrAzureApiService } from '../api/services';

//probably not the best way to do this... doing this to avoid pinging the keyvault everytime we need the key.
export var GoogleApiKey = '';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  constructor(private careFindrAzureApiService: CareFindrAzureApiService) {}

  initializeGoogleApi(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.careFindrAzureApiService
        .getAzureConfig({ keyVaultKey: 'GoogleApiKey' })
        .subscribe({
          next: (apiKey) => {
            GoogleApiKey = apiKey;
            loadGoogleMaps(apiKey, [GoogleMapsLibraries.places])
              .then(() => {
                resolve();
              })
              .catch(reject);
          },
          error: (err) => reject(err),
        });
    });
  }
}
