/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClarityLoadingTask } from '../models/clarity-loading-task';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation elasticSearchHeartBeatCheck_1
   */
  static readonly ElasticSearchHeartBeatCheck_1Path = '/elastic-search/health';

  /**
   * Check the integration with the elastic search nodes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticSearchHeartBeatCheck_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticSearchHeartBeatCheck_1$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.ElasticSearchHeartBeatCheck_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Check the integration with the elastic search nodes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticSearchHeartBeatCheck_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticSearchHeartBeatCheck_1(params?: {
  },
  context?: HttpContext

): Observable<{
}> {

    return this.elasticSearchHeartBeatCheck_1$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation queueClarityLoadingTask
   */
  static readonly QueueClarityLoadingTaskPath = '/clarity/queue-task';

  /**
   * Submit a carrier file loading task to the Clarity API.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueClarityLoadingTask()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  queueClarityLoadingTask$Response(params: {

    /**
     * Clarity panel loading task.
     */
    body: ClarityLoadingTask
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ClarityLoadingTask>> {

    const rb = new RequestBuilder(this.rootUrl, IntegrationService.QueueClarityLoadingTaskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClarityLoadingTask>;
      })
    );
  }

  /**
   * Submit a carrier file loading task to the Clarity API.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queueClarityLoadingTask$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  queueClarityLoadingTask(params: {

    /**
     * Clarity panel loading task.
     */
    body: ClarityLoadingTask
  },
  context?: HttpContext

): Observable<ClarityLoadingTask> {

    return this.queueClarityLoadingTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClarityLoadingTask>) => r.body as ClarityLoadingTask)
    );
  }

}
