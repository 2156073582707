import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  SearchState,
  SearchStateService,
} from '../_core/services/search-state.service';
import { omitBy, isNull } from 'lodash-es';
import { SearchService } from '../_core/services/search.service';
import { Router } from '@angular/router';
import { Subject, Subscription, forkJoin, takeUntil } from 'rxjs';
import { LoadingSpinnerService } from '../_core/services/loading-spinner.service';
import { CareFindrConfigApiService } from '../_core/api/services';
import {CareFindrConfig, SpecialtyMappingEntry} from '../_core/api/models';
import { FeatureFlagService } from '../_core/services/feature-flag.service';
import { CURRENT_TENNANT } from '../app.component';
import {Dropdown, ErrorMessages} from 'lo-bahamut';
import { CareFindrTenantConfigurationService } from '../_core/api/services/care-findr-tenant-configuration.service';
import {SliderComponent} from "../_shared/slider/slider.component";
import {LocationAutocompleteComponent} from "../_shared/location-autocomplete/location-autocomplete.component";
import {level} from "lo-bahamut/types";
// import { ErrorMessages } from 'lo-bahamut/services/validation/errorMessages.service';

export type option = {
  value: string;
  description: string;
};

@Component({
  selector: 'app-search-start',
  templateUrl: './search-start.component.html',
  styleUrls: ['./search-start.component.scss'],
})
export class SearchStartComponent implements OnInit, OnDestroy {
  @ViewChild('planDropdown') planDropdown: Dropdown;
  @ViewChild('sliderComponent') sliderComponent: SliderComponent;
  @ViewChild('locationAutocomplete') locationAutocomplete: LocationAutocompleteComponent;
  @ViewChild('specialtyDropdown') specialtyDropdown: Dropdown;
  @ViewChild('nameInput') nameInput: Input;
  isNameLength!: boolean;
  isSpecialtyEmpty!: boolean;
  errorMessagesData = {
    "invalidCharacters": "Please check for invalid characters.",
    "emptyRequired": "Required.",
    "minMax": "Your response must be between ||this|| and ||that||.",
    "min": "Your response must be equal to or greater than ||this||.",
    "max": "Your response must be equal to or less than ||this||.",
    "minlength": "Your response must be at least ||this|| character(s).",
    "maxlength": "Your response must be equal to or less than ||this|| character(s).",
    "dateFormat": "Your response must be a valid ||this|| date.",
    "minDate": "This date must be on or after ||this||.",
    "maxDate": "This date must be on or before ||this||."
  }
  locationTextValue: string | undefined;
  levelsTest: level[] = []
  constructor(
    private featureFlagService: FeatureFlagService,
    private configApiService: CareFindrConfigApiService,
    private loadingSpinnerService: LoadingSpinnerService,
    private router: Router,
    private searchService: SearchService,
    private searchStateService: SearchStateService,
    private tenantConfigService: CareFindrTenantConfigurationService,
    private errorMessages: ErrorMessages
  ) {}

  comingSoonFeatureFlagSubscription!: Subscription;

  debounceDelay = 300;
  destroyed$ = new Subject<void>();

  planOptions: option[] = [];

  specialtyOptions: level[] = [];

  loadingSearchResults = false;

  searchState: SearchState = {
    Query: {},
    Extra: {},
  };

  printState: SearchState = {
    Query: {},
    Extra: {},
  };

  config: any;

  showComingSoon = true;
  isPlanDropDownEnabled = true;
  isSpecialityHeaderNeedToChange = false;

  defaultSlideValue: string[] = ['25'];

  ngOnInit() {
    this.loadingSpinnerService.showLoader();
    //TODO: To be revised after configuration driven UI is implemented
    if(CURRENT_TENNANT === 'centerlight' || CURRENT_TENNANT === 'mddoh') {
      this.isPlanDropDownEnabled = false;
    }
    //TODO: To be revised after configuration driven UI is implemented
    if(CURRENT_TENNANT === 'mddoh') {
      this.isSpecialityHeaderNeedToChange = true;
    }

    this.comingSoonFeatureFlagSubscription = this.featureFlagService
      .getFeatureFlag('showComingSoon')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((flagValue) => {
        this.showComingSoon = flagValue;
      });

    const tenantConfig$ = this.configApiService.getCareFindrTenantConfig({tenant: CURRENT_TENNANT});
    const tenantComponentJs$ = this.tenantConfigService.getTenantComponentJs({tenant: CURRENT_TENNANT});

    forkJoin([tenantConfig$, tenantComponentJs$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([configResponse, componentJsResponse]) => {
          this.planOptions = configResponse.planDropdown?.options as option[];

          this.specialtyOptions = componentJsResponse.specialtyDropdown?.options?.map((option) => {
            return {
              value: option.value as string,
              description: option.description as string,
              children: option.children as level[]
            };
          }).sort((a, b) => {
            return a.description!.localeCompare(b.description!);
          }) as level[];

          this.planOptions = componentJsResponse.planDropdown?.options as option[];
        },
        error: (err) => {
          if (err.status === 403) {
            console.error('You are not authorized to view this', err);
            this.router.navigate(['error-handler'], {state: { msg:"Access denied"}});
          } else {
            console.error('Please try again later or contact support.', err);
          }
        },
        complete: () => {
          this.loadingSpinnerService.hideLoader();
        }
      });

    this.searchStateService.searchState$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state) => {
        this.searchState = state;
      });

    this.errorMessages.defaultErrorMessages = this.errorMessagesData;
  }


  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleSearchClick() {
    this.printState = this.searchState;
    const queryParams = {
      ...omitBy(this.searchState.Query, isNull),
      skip: 0,
      limit: 5,
    };

    const printQueryParams = {
      ...omitBy(this.printState.Query, isNull),
      skip: 0,
      limit: 200
    }

    //only navigate to search-results after both calls return with a response
    this.loadingSpinnerService.showLoader();
    forkJoin({
      providerResults: this.searchService.getProviderResults(queryParams),
      facilityResults: this.searchService.getFacilityResults(queryParams),
    }).subscribe({
      next: () => {
        this.router.navigate(['search-results']);
      },
      error: (err) => {
        console.log('An error occurred:', err);
      },
      complete: () => {
        this.loadingSpinnerService.hideLoader();
      },
    });

    forkJoin({
      printProviderResults: this.searchService.getProviderResults(printQueryParams, true),
      printFacilityResults: this.searchService.getFacilityResults(printQueryParams, true),
    }).subscribe({
      error: (err) => {
        console.log('An error occurred:', err);
      }
    });
  }

  handleNameInput(value: string) {
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, name: value },
    });
    this.isNameLength = value.length > 1;
  }

  handleSpecialtyDropdown(value: string[]) {
    if(value.length > 0){
      this.isSpecialtyEmpty = true;
      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          // specialties: [JSON.parse(value)],
          specialtiesV2: this.specialtyDropdown.data.concat(this.specialtyDropdown.pills)
        }
      });
    } else {
      this.isSpecialtyEmpty = false;
    }
  }

  handleSliderValueChange(value: string[]) {
    const distValue = value[0];

    if(distValue){
      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          location: {
            ...this.searchState.Query.location,
            distance: distValue.toString(),
          },
        },
      });
    }
  }

  // handlePlanDropdown(value: string[]) {
  //   this.searchStateService.updateSearchCriteria({
  //     Query: { ...this.searchState.Query, programType: value === 'PACE' ? undefined : value },
  //   });
  // }

  handlePlanDropdown(value: string[]) {
    const programType: string | undefined = value[0];
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, programType: programType },
    });
  }

  clearSearchInputs() {
    this.searchStateService.clearSearchCriteria();
    this.defaultSlideValue = ['25'];
    this.sliderComponent.selectedValue = this.defaultSlideValue;
    this.sliderComponent.distanceOption.data = this.defaultSlideValue;
    this.sliderComponent.updateData(this.defaultSlideValue);
    this.searchState.Query.location = undefined;
    this.searchState.Query.name = undefined;
    this.locationAutocomplete.inputField.nativeElement.value= '';
    if(this.planDropdown) {
      this.searchState.Query.programType = undefined;
      this.planDropdown.data = [];
      this.planDropdown.optionClicked('');
      this.planDropdown.selected = []
      this.planOptions.forEach((option: any) => {
        option['selected'] = (option.value === this.planDropdown.data);
      });    }
    this.specialtyDropdown.data = [];
    this.specialtyDropdown.optionClicked('');
    this.specialtyDropdown.filterValue = '';
    this.specialtyDropdown.ddFilterInput.nativeElement.value='';
    this.specialtyDropdown.pills = [];
    this.specialtyDropdown.selected = [];
    this.specialtyDropdown.tree.clearTreeCheckbox();
    this.specialtyDropdown.filteringOptions();
  }

  isSearchButtonEnabled(): boolean {
    return this.searchState.Extra.locationText != undefined &&
          ((this.searchState.Query.name != undefined && this.isNameLength)||
          (this.searchState.Query.specialtiesV2 != undefined &&
          this.searchState.Query.specialtiesV2.length > 0 && this.isSpecialtyEmpty))
  }

  locationFocus(){
    document.getElementById('location-input')?.focus()
  }
}
