import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  BasicProviderSearchQuery,
  BasicOrganizationSearchQuery,
} from '../api/models';
import {level} from "lo-bahamut/types";

export interface SearchState {
  Query: BasicProviderSearchQuery | BasicOrganizationSearchQuery;
  Extra: {
    locationText?: string;
    testMulti?: string[];
  };
}

@Injectable({
  providedIn: 'root', // Makes it a singleton
})
export class SearchStateService {
  private searchStateSubject = new BehaviorSubject<SearchState>({
    Query: {
      sortBy: 'distance-asc',
    },
    Extra: {},
  });

  searchState$ = this.searchStateSubject.asObservable();

  updateSearchCriteria(newCriteria: Partial<SearchState>): void {
    const currentCriteria = this.searchStateSubject.getValue();
    const updatedCriteria = { ...currentCriteria, ...newCriteria };
    this.searchStateSubject.next(updatedCriteria);
  }

  getSearchCriteria(): SearchState {
    return this.searchStateSubject.getValue();
  }

  clearSearchCriteria(): void {
    this.updateSearchCriteria({
      Query: {
        //need to preserve programType selected from search-start
        programType: this.getSearchCriteria().Query.programType,
        sortBy: 'distance-asc',
      },
      Extra: {},
    });
  }
}
