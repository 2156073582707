/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ComponentJsonRoot } from '../models/component-json-root';

@Injectable({
  providedIn: 'root',
})
export class CareFindrTenantConfigurationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAzureFeatureFlagAsync
   */
  static readonly GetAzureFeatureFlagAsyncPath = '/carefindr-azure-api/feature-flag/{featureFlagName}';

  /**
   * GET Azure Feature Flag settings through server so that keys are never exposed to client.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAzureFeatureFlagAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAzureFeatureFlagAsync$Response(params: {

    /**
     * The feature-flag key/name to look for in azure app configuration
     */
    featureFlagName: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CareFindrTenantConfigurationService.GetAzureFeatureFlagAsyncPath, 'get');
    if (params) {
      rb.path('featureFlagName', params.featureFlagName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * GET Azure Feature Flag settings through server so that keys are never exposed to client.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAzureFeatureFlagAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAzureFeatureFlagAsync(params: {

    /**
     * The feature-flag key/name to look for in azure app configuration
     */
    featureFlagName: string;
  },
  context?: HttpContext

): Observable<boolean> {

    return this.getAzureFeatureFlagAsync$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation getAzureConfig
   */
  static readonly GetAzureConfigPath = '/carefindr-azure-api/key-vault/{keyVaultKey}';

  /**
   * Attempt to GET secret value from azure key-vault.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAzureConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAzureConfig$Response(params: {

    /**
     * The key used to lookup config. Key-vault secret keys applicable. Returns null if no secret with that key exists in the key-vault.
     */
    keyVaultKey: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CareFindrTenantConfigurationService.GetAzureConfigPath, 'get');
    if (params) {
      rb.path('keyVaultKey', params.keyVaultKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Attempt to GET secret value from azure key-vault.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAzureConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAzureConfig(params: {

    /**
     * The key used to lookup config. Key-vault secret keys applicable. Returns null if no secret with that key exists in the key-vault.
     */
    keyVaultKey: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.getAzureConfig$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTenantComponentJs
   */
  static readonly GetTenantComponentJsPath = '/tenants/{tenant}/component-js';

  /**
   * GET Tenant specific Component JSON.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantComponentJs()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantComponentJs$Response(params: {

    /**
     * The tenant name we will use as a key to lookup their config in MongoDB. Falls-back to default config if specified tenant not found.
     */
    tenant: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ComponentJsonRoot>> {

    const rb = new RequestBuilder(this.rootUrl, CareFindrTenantConfigurationService.GetTenantComponentJsPath, 'get');
    if (params) {
      rb.path('tenant', params.tenant, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentJsonRoot>;
      })
    );
  }

  /**
   * GET Tenant specific Component JSON.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantComponentJs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantComponentJs(params: {

    /**
     * The tenant name we will use as a key to lookup their config in MongoDB. Falls-back to default config if specified tenant not found.
     */
    tenant: string;
  },
  context?: HttpContext

): Observable<ComponentJsonRoot> {

    return this.getTenantComponentJs$Response(params,context).pipe(
      map((r: StrictHttpResponse<ComponentJsonRoot>) => r.body as ComponentJsonRoot)
    );
  }

  /**
   * Path part for operation getCareFindrTenantConfig
   */
  static readonly GetCareFindrTenantConfigPath = '/carefindr-config-api/{tenant}';

  /**
   * (DO NOT USE) GET Tenant Config settings stored in MongoDB.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCareFindrTenantConfig()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCareFindrTenantConfig$Response(params: {

    /**
     * (DO NOT USE) The tenant name we will use as a key to lookup their config in MongoDB. Falls-back to default config if specified tenant not found.
     */
    tenant: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ComponentJsonRoot>> {

    const rb = new RequestBuilder(this.rootUrl, CareFindrTenantConfigurationService.GetCareFindrTenantConfigPath, 'get');
    if (params) {
      rb.path('tenant', params.tenant, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComponentJsonRoot>;
      })
    );
  }

  /**
   * (DO NOT USE) GET Tenant Config settings stored in MongoDB.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCareFindrTenantConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  getCareFindrTenantConfig(params: {

    /**
     * (DO NOT USE) The tenant name we will use as a key to lookup their config in MongoDB. Falls-back to default config if specified tenant not found.
     */
    tenant: string;
  },
  context?: HttpContext

): Observable<ComponentJsonRoot> {

    return this.getCareFindrTenantConfig$Response(params,context).pipe(
      map((r: StrictHttpResponse<ComponentJsonRoot>) => r.body as ComponentJsonRoot)
    );
  }

}
