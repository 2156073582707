import { Injectable } from '@angular/core';
import { AggregateRootOfPractitionerPractitionerRole } from '../../api/models/aggregate-root-of-practitioner-practitioner-role';
import {find, forEach, filter} from 'lodash-es';
import { AggregateRootOfFacilityOrganizationAffiliation } from '../../api/models/aggregate-root-of-facility-organization-affiliation';
import {Address, Facility, Practitioner, Specialty} from '../../api/models';

export interface SearchCardData {
  list: {
    //temp removing until we figure out tying min_distance with location
    Address: Address;
    Phone: string | undefined;
    Gender?: string;
    BoardCertified?: string | undefined;
    Fax: string | undefined;
    Website: string | undefined;
  };
  extras: {
    RootStableId: string;
    Name: string | undefined;
    Specialty: string | undefined;
    MinDistance: string | undefined;
    StandardAddress: StandardAddress | undefined;
  };
  identifiers: {
    Licenses: string[];
  };
}

export interface StandardAddress {
  LineOne: string,
  LineTwo: string
}

@Injectable({
  providedIn: 'root',
})
export class SearchResultCardAdapterService {
  constructor() {}

  private getUniqueIdentifiersProvider(data: Practitioner | undefined): string[] {
    const res: string[] = [];
    if (data && data.identifier) {
      forEach(data.identifier, (c) => {
        if (c.type == 'NPI' || c.type == 'LN') {
          res.push(`${c.type}: ${c.value}`);
        }
      });
    }
    return res;
  }
  private getPrimarySpecialty(data: Array<Specialty> | null | undefined): string | undefined{
    if(data){
      const res = filter(data, ['primary' ,true]);
      const primaryName = res[0].display;
      return primaryName;
    }else{
      return 'N/A'
    }
  }
  adaptProvider(
    data: AggregateRootOfPractitionerPractitionerRole
  ): SearchCardData {
    const goldenRecord = find(data?.heads, { golden: true });

    let temp = data.relations![0];
    let tempAddress = temp.location![0].address![0];
    const regex = /(\d+\.\d{4})\d*/;

    let minDistance = String(data.meta!['min_distance']).replace(regex, '$1');
    // let certified = temp.specialty?[0].attrs.boardCertified;

    // Extract telecom information
    const telecomList = temp.location?.[0]?.telecom || [];
    const phone = telecomList.find(item => item.system === 'phone')?.value || '';
    const fax = telecomList.find(item => item.system === 'fax')?.value || '';
    const website = telecomList.find(item => item.system === 'website')?.value || '';
    return {
      list: {
        Address: tempAddress,
        Phone: phone,
        Gender: goldenRecord?.gender,
        // BoardCertified: certified,
        Fax: fax,
        Website: website,
      },
      extras: {
        RootStableId: data.stableId!,
        Name: goldenRecord?.name![0].text!,
        Specialty: filter(temp.specialty, ['primary' ,true])[0]?.display ?? 'N/A',
        MinDistance: minDistance,
        StandardAddress: this.buildStandardAddress(temp.location![0].address![0])
      },
      identifiers: {
        Licenses: this.getUniqueIdentifiersProvider(goldenRecord),
      },
    };
  }

  private getUniqueIdentifiersFacility(data: Facility | undefined): string[] {
    let res = new Set<string>();
    if (data) {
      const facilityIdentifier = data.identifier;
      forEach(facilityIdentifier, (i) => {
        if (i.type != 'TAX') {
          res.add(`${i.type}: ${i.value}`);
        }
      });
    }

    return Array.from(res);
  }

  adaptFacility(
    data: AggregateRootOfFacilityOrganizationAffiliation
  ): SearchCardData {
    const goldenRecord = find(data?.heads, { golden: true });
    let temp = data.relations![0];
    let tempAddress = temp.location![0].address![0];
    const regex = /(\d+\.\d{4})\d*/;

    let minDistance = String(data.meta!['min_distance']).replace(regex, '$1');

    // Extract telecom information
    const telecomList = temp.location?.[0]?.telecom || [];
    const phone = telecomList.find(item => item.system === 'phone')?.value || '';
    const fax = telecomList.find(item => item.system === 'fax')?.value || '';
    const website = telecomList.find(item => item.system === 'website')?.value || '';

    return {
      list: {
        Address: tempAddress,
        Phone: phone,
        Fax: fax,
        Website: website,
      },
      extras: {
        RootStableId: data.stableId!,
        Name: goldenRecord?.name![0],
        Specialty: filter(temp.specialty, ['primary' ,true])[0]?.display ?? 'N/A',
        MinDistance: minDistance,
        StandardAddress: this.buildStandardAddress(temp.location![0].address![0])
      },
      identifiers: {
        Licenses: this.getUniqueIdentifiersFacility(goldenRecord),
      },
    };
  }

  buildStandardAddress(address: Address): StandardAddress {
    let lineOne = '';
    let lineTwo = '';
    if(address.line) {
      lineOne = address.line[0]
      if(address.line.length > 1) {
        lineOne += ' ' + address.line[1]
      }
    }
    lineTwo = address.city + ', ' + address.state + ', ' + address.postalCode?.split('-')[0]

    return {LineOne: lineOne, LineTwo: lineTwo}
  }
}
