const CALLBACK_NAME = 'initMap';

export enum GoogleMapsLibraries {
  /** provides a graphical interface for users to draw polygons, rectangles, polylines, circles, and markers on the map. Consult the [Drawing library documentation](https://developers.google.com/maps/documentation/javascript/drawinglayer) for more information. */
  drawing = 'drawing',
  /** includes utility functions for calculating scalar geometric values (such as distance and area) on the surface of the earth. Consult the [Geometry library documentation](https://developers.google.com/maps/documentation/javascript/geometry) for more information. */
  geometry = 'geometry',
  /** shows users key places of interest near a location that you specify. Consult the Local [Context library documentation](https://developers.google.com/maps/documentation/javascript/local-context) for more information. */
  localContext = 'localContext',
  /** enables your application to search for places such as establishments, geographic locations, or prominent points of interest, within a defined area. Consult the [Places library documentation](https://developers.google.com/maps/documentation/javascript/places) for more information. */
  places = 'places',
  /** provides heatmaps for visual representation of data. Consult the [Visualization library documentation](https://developers.google.com/maps/documentation/javascript/visualization) for more information. */
  visualization = 'visualization',
}

// REFERENCE: https://stackoverflow.com/a/68620996
export function loadGoogleMaps(
  googleMapsKey: string,
  libraries: GoogleMapsLibraries[] = []
) {
  if (!window) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve, reject) => {
    function onError(err?: any) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      (window as any)[CALLBACK_NAME] = () => {}; // Set the on load callback to a no-op
      scriptElement.removeEventListener('error', onError);
      reject(err || new Error('Could not load the Google Maps API'));
    }

    // Reject the promise after a timeout
    const timeoutId = setTimeout(() => onError(), 10000);

    // Hook up the on load callback
    (window as any)[CALLBACK_NAME] = () => {
      clearTimeout(timeoutId);
      scriptElement.removeEventListener('error', onError);
      resolve();
      delete (window as any)[CALLBACK_NAME];
    };

    // Deduplicate libraries
    libraries = [...new Set(libraries)];

    // Prepare the `script` tag to be inserted into the page
    const scriptElement = document.createElement('script');
    scriptElement.addEventListener('error', onError);
    scriptElement.async = true;
    scriptElement.defer = true;

    scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&language=en&callback=${CALLBACK_NAME}&libraries=${libraries.join(
      ','
    )}`;
    document.head.appendChild(scriptElement);
  });
}
