/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CacheRefreshCommand } from '../models/cache-refresh-command';
import { PostNuccCodesMappingRequest } from '../models/post-nucc-codes-mapping-request';

@Injectable({
  providedIn: 'root',
})
export class SystemConfigurationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation signalCacheRefresh
   */
  static readonly SignalCacheRefreshPath = '/configuration/cache/reload';

  /**
   * Refresh all cached configuration. Noted that this will signal all the instances of PurviewAPI.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signalCacheRefresh()` instead.
   *
   * This method doesn't expect any request body.
   */
  signalCacheRefresh$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CacheRefreshCommand>>> {

    const rb = new RequestBuilder(this.rootUrl, SystemConfigurationService.SignalCacheRefreshPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CacheRefreshCommand>>;
      })
    );
  }

  /**
   * Refresh all cached configuration. Noted that this will signal all the instances of PurviewAPI.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signalCacheRefresh$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signalCacheRefresh(params?: {
  },
  context?: HttpContext

): Observable<Array<CacheRefreshCommand>> {

    return this.signalCacheRefresh$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CacheRefreshCommand>>) => r.body as Array<CacheRefreshCommand>)
    );
  }

  /**
   * Path part for operation updateTemplateByName
   */
  static readonly UpdateTemplateByNamePath = '/configuration/nucc-codes-mapping/reload';

  /**
   * Load the WAHBE Specialty to NUCC Codes mapping csv file to the configuration store.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplateByName()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateTemplateByName$Response(params: {

    /**
     * Csv file which contains the WAHBE Specialty Code to NUCC Codes mapping.
     */
    body: PostNuccCodesMappingRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SystemConfigurationService.UpdateTemplateByNamePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Load the WAHBE Specialty to NUCC Codes mapping csv file to the configuration store.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplateByName$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateTemplateByName(params: {

    /**
     * Csv file which contains the WAHBE Specialty Code to NUCC Codes mapping.
     */
    body: PostNuccCodesMappingRequest
  },
  context?: HttpContext

): Observable<string> {

    return this.updateTemplateByName$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
