/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateQueryDslTemplateRequest } from '../models/create-query-dsl-template-request';
import { ProviderSearchRequest } from '../models/provider-search-request';
import { QueryDslTemplateMeta } from '../models/query-dsl-template-meta';
import { UpdateQueryDslTemplateRequest } from '../models/update-query-dsl-template-request';

@Injectable({
  providedIn: 'root',
})
export class QueryDslManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTemplates
   */
  static readonly GetTemplatesPath = '/templates';

  /**
   * Get all the template names.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<QueryDslTemplateMeta>>> {

    const rb = new RequestBuilder(this.rootUrl, QueryDslManagementService.GetTemplatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QueryDslTemplateMeta>>;
      })
    );
  }

  /**
   * Get all the template names.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplates(params?: {
  },
  context?: HttpContext

): Observable<Array<QueryDslTemplateMeta>> {

    return this.getTemplates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<QueryDslTemplateMeta>>) => r.body as Array<QueryDslTemplateMeta>)
    );
  }

  /**
   * Path part for operation createQueryTemplate
   */
  static readonly CreateQueryTemplatePath = '/templates';

  /**
   * Create handlebar elastic search query template.
   *
   * Place holder
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createQueryTemplate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createQueryTemplate$Response(params: {

    /**
     * Query handlebar template file
     */
    body: CreateQueryDslTemplateRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, QueryDslManagementService.CreateQueryTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Create handlebar elastic search query template.
   *
   * Place holder
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createQueryTemplate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createQueryTemplate(params: {

    /**
     * Query handlebar template file
     */
    body: CreateQueryDslTemplateRequest
  },
  context?: HttpContext

): Observable<string> {

    return this.createQueryTemplate$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getTemplateByName
   */
  static readonly GetTemplateByNamePath = '/templates/{name}';

  /**
   * Fetch the query DSL handlebar template by the given name.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTemplateByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplateByName$Response(params: {

    /**
     * The name of the template to fetch
     */
    name: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, QueryDslManagementService.GetTemplateByNamePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Fetch the query DSL handlebar template by the given name.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTemplateByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTemplateByName(params: {

    /**
     * The name of the template to fetch
     */
    name: string;
  },
  context?: HttpContext

): Observable<string> {

    return this.getTemplateByName$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateTemplateByName_1
   */
  static readonly UpdateTemplateByName_1Path = '/templates/{name}';

  /**
   * Update an existing ES query handlebar template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplateByName_1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateTemplateByName_1$Response(params: {

    /**
     * The name of the template to fetch
     */
    name: string;

    /**
     * Query handlebar template file
     */
    body: UpdateQueryDslTemplateRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, QueryDslManagementService.UpdateTemplateByName_1Path, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Update an existing ES query handlebar template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplateByName_1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateTemplateByName_1(params: {

    /**
     * The name of the template to fetch
     */
    name: string;

    /**
     * Query handlebar template file
     */
    body: UpdateQueryDslTemplateRequest
  },
  context?: HttpContext

): Observable<string> {

    return this.updateTemplateByName_1$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation previewTemplateBindingByName
   */
  static readonly PreviewTemplateBindingByNamePath = '/templates/{name}/preview';

  /**
   * Preview handlebar template binding.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `previewTemplateBindingByName()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  previewTemplateBindingByName$Response(params: {

    /**
     * The name of the template to be used.
     */
    name: string;

    /**
     * Sample requests as the datasource.
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, QueryDslManagementService.PreviewTemplateBindingByNamePath, 'post');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Preview handlebar template binding.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `previewTemplateBindingByName$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  previewTemplateBindingByName(params: {

    /**
     * The name of the template to be used.
     */
    name: string;

    /**
     * Sample requests as the datasource.
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<string> {

    return this.previewTemplateBindingByName$Response(params,context).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
