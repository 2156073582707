<lo-container nogm maincontent class="noPrint">
  <div class="main-content">
    <div class="breadcrumbs-block flex space-between">
      <div class="breadcrumbs">
        <a href="/">Home</a> > Results
      </div>
      <button class="filter-menu" (click)="filterToggle($event)">
        Edit search
      </button>
    </div>
    <lo-container
      nogm
      class="main-search-content-bottom align-start"
      col="12"
    >
      <lo-container nogm col="3" class="sidebar align-baseline bg-white" [ngClass]="{'opened': toggle}">
        <div class="filter-menu-close" [ngClass]="{'active': toggle}" (click)="filterToggle($event)">
          <span class="material-symbols-outlined">close</span>
        </div>
        <lo-container nogm [title]="'Filters Applied'">
          <div class="sidebar-list">
            <!-- TODO: replace line below with a div containing a list/set of chips that represent filter criteria  -->
            <div class="required-fields">
              <h2 class="refine-result-title">Required Search Criteria</h2>
              <div class="select-plan-block" *ngIf="isPlanDropDownEnabled">
                <lo-dropdown *ngIf="planOptions.length > 0"
                             nogm
                             id="plan-dropdown"
                             variant="basic"
                             placeholder="Select Your Plan"
                             label="Select Your Plan"
                             errormessage="test error message"
                             required="false"
                             title=""
                             [attr.selected] = "selectedPlan ?? ''"
                             [options]="planOptions"
                             (onChange)="handlePlanDropdown($event)"
                             #planDropdown
                >
                </lo-dropdown>
              </div>
              <div class="loc-range">
                <div class="location-input">
                  <label class="location-input-header" data-required="true" for="location-input">Choose Your Search Location</label>
                  <app-location-autocomplete
                    [placeHolder]="searchState.Extra.locationText ?? ''"
                    (locationText)=getLocationText($event)
                    #locationAutocomplete
                  />
                </div>
                <div class="range">
                  <label class="slider-label" for="miles-range">Within Distance:</label>
                  <app-slider
                    class="flex"
                    [minValue]="5"
                    [maxValue]="100"
                    [tickInterval]="5"
                    [useTicks]="true"
                    [currentValue]="[searchState.Query.location?.distance ?? defaultSlideValue]"
                    (valueChange)="handleSliderValueChange($event)"
                    [selectedValue] = "[defaultSlideValue]"
                    #sliderComponent
                  />
                </div>
              </div>
              <div class="name-speci">
                <lo-input
                  nogm
                  id="name-input"
                  variant="text"
                  label="Provider / Facility Name"
                  placeholder="Provider / Facility Name"
                  [data]="searchState.Query.name ?? ''"
                  errormessage="There are special characters included that is not accepted. Please retype your search without special characters and try again."
                  maxlength="100"
                  minlength="2"
                  (onChange)="handleNameInput($event)"
                >
                </lo-input>
                <div class="or-text" data-required="true">or</div>
                <lo-dropdown *ngIf="specialtyOptions.length > 0"
                             #specialtyDropdown
                             nogm
                             id="specialty-select"
                             variant="tree-multiselect"
                             label="{{isSpecialityHeaderNeedToChange ? 'Provider Type / Specialty' : 'Specialty'}}"
                             placeholder="{{isSpecialityHeaderNeedToChange ? 'Provider Type / Specialty' : 'Specialty'}}"
                             [levels]="specialtyOptions"
                             (onChange)="handleTreeSpecialtyDropdown($event)"
                             [role]="'combobox'"
                             [ariaLabel]="'Specialty multiselect'">
                </lo-dropdown>
              </div>
            </div>
            <div class="filter-block">
              <h2 class="filter-title">Filters</h2>
              <div class="lang-gen">
                <lo-dropdown *ngIf="languageOptions.length > 0"
                             nogm
                             id="language-dropdown"
                             variant="multiselect"
                             label="Language"
                             placeholder="Select a Language"
                             errormessage="test error message"
                             [options]="languageOptions"
                             (onChange)="handleLanguageDropdown($event)"
                             [role]="'combobox'"
                             [ariaLabel]="'Language'"
                             #languageDropdown
                >
                </lo-dropdown>
                <lo-dropdown *ngIf="genderOptions.length > 0"
                             nogm
                             id="gender-dropdown"
                             variant="basic"
                             label="Gender"
                             placeholder="Select a Gender"
                             errormessage="test error message"
                             [options]="genderOptions"
                             (onChange)="handleGenderDropdown($event)"
                             [role]="'combobox'"
                             [ariaLabel]="'Gender'"
                             #genderDropdown
                >
                </lo-dropdown>
              </div>
              <!-- TODO: load these checkboxes according to some config, some tenants may not want the same checkboxes -->
              <div class="check-box-list">
                <lo-checkbox
                  label="Accepting New Patients"
                  nogm
                  id="accept-new-pt"
                  (onChange)="handleAcceptingNewPatient($event)"
                  value="Accepting New Patients"
                  aria-label="Accepting New Patients"
                  #aNPChecked
                ></lo-checkbox>
                <lo-checkbox
                  label="Handicap Accessible"
                  nogm
                  id="handicap-accessible"
                  (onChange)="handleHandicapAccessible($event)"
                  value="Handicap Accessible"
                  aria-label="Handicap Accessible"
                  #hAChecked
                ></lo-checkbox>
                <lo-checkbox
                  label="Primary Care Provider (PCP)"
                  nogm
                  id="primary-care-provider"
                  (onChange)="handlePCP($event)"
                  value="Primary Care Provider (PCP)"
                  aria-label="Primary Care Provider (PCP)"
                  #pCPChecked
                ></lo-checkbox>
              </div>
            </div>
          </div>
        </lo-container>
      </lo-container>
      <lo-container nogm col="9" class="main-search-result-block flex-column">
        <div class="filtered-list flex flex-column">
          <!--          <div class="filtered-list flex" *ngIf="locationValue ||nameValue||specialtyValue ||slidedValue || searchState.Query.language || genderValue || isANPChecked || isHAChecked || isPCPChecked">-->
          <div class="filter-title flex align-center">Filters: </div>
          <div class="filter-container flex space-between">
            <ul class="flex align-center flex-wrap">
              <ng-container *ngIf="langValue.length >= 1">
                <li class="flex align-center" tabindex="0">{{ langValue[0] }} <ng-container *ngIf="langValue.length - 1"><span class="material-symbols-outlined">add</span> {{ langValue.length - 1 }} <button class="material-symbols-outlined" (click)="showCombinedLang($event)" #langPillToggle>{{langToggleIcon}}</button></ng-container><button class="material-symbols-outlined" *ngIf="langValue.length == 1" (click)="deselectLang(0)">close</button></li>
              </ng-container>

              <ng-container *ngIf="langToggle">
                <!-- Card block element -->
                <div class="flex-column lang-card" [style.top.px]="cardTopLang" [style.left.px]="cardLeftLang" *ngIf="langValue.length > 1" #langPillContainer>
                  <ul class="flex align-baseline">
                    <li class="flex align-center not-required" *ngFor="let lang of langValue.slice(1); let i = index" tabindex="0">
                      {{ lang }}
                      <button class="material-symbols-outlined close-button" (click)="deselectLang(i+1)">close</button>
                    </li>
                  </ul>
                  <lo-button
                    nogm
                    id="clear-language-pills-btn"
                    label="Remove all"
                    class="remove-all-btn"
                    (onClick)="removeLanguagePills()"
                  ></lo-button>
                </div>
              </ng-container>

              <li *ngIf="genderValue" class="flex align-center" tabindex="0"> {{genderValue}} <button class="material-symbols-outlined" (click)="closeSelectedFilters(genderValue)">close</button></li>
              <li *ngIf="isANPChecked" class="flex align-center" tabindex="0">{{acpValue}} <button class="material-symbols-outlined" (click)="closeSelectedFilters(isANPChecked)">close</button></li>
              <li *ngIf="isHAChecked" class="flex align-center" tabindex="0">{{haValue}} <button class="material-symbols-outlined" (click)="closeSelectedFilters(isHAChecked)">close</button></li>
              <li *ngIf="isPCPChecked" class="flex align-center" tabindex="0">{{pcpValue}} <button class="material-symbols-outlined" (click)="closeSelectedFilters(isPCPChecked)">close</button></li>
            </ul>
            <div class="clear-filter-block align-center flex">
              <lo-button
                nogm
                id="clear-button"
                variant="cancel"
                label="Clear filters"
                tooltip="Clear form"
                tooltipDirection="left"
                (onClick)="clearFilterInputs()"
              ></lo-button>
            </div>
          </div>
        </div>
        <lo-container nogm class="main-search-result-block-top justify-end">
          <div class="result-heading">
            Results
          </div>
          <!--        sort-by-block is hidden for now for all the tenants  -->
          <div class="sort-by-block" style="display: none">
            <lo-dropdown *ngIf="sortOptions.length > 0"
                         nogm
                         id="sort-by-dropdown"
                         variant="basic"
                         label="Sort By"
                         placeholder="Sort By"
                         errormessage="test error message"
                         [options]="sortOptions"
                         [data]="[sortOptions[0].value]"
            >
            </lo-dropdown>
          </div>
          <div class="flex align-end print-button-block">
            <ng-container *ngIf="!printReady()">
              <svg class="spinner" width="15" height="15" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
              </svg>
            </ng-container>
            <ng-container *ngIf="printReady()">
              <lo-button
                nogm
                id="print-button"
                icon="print"
                label="Print Results"
                class="action-icon"
                (onClick)="print()"
                [attr.disabled]="printReady() ? null : 'disabled'"
              ></lo-button>
            </ng-container>
          </div>
        </lo-container>
        <div class="main-search-result-block-bottom">
          <div class="tab-block flex flex-column">
            <div class="tab-nav flex">
              <button
                class="tab-nav-item"
                (click)="switchTab('provider')"
                [class.active]="currentTab === 'provider'"
                tabindex="0"
              >
                Provider <span class="count">{{ providerSearchResults ? providerSearchResults.total : 0 }}</span>
              </button>
              <button
                class="tab-nav-item"
                (click)="switchTab('facility')"
                [class.active]="currentTab === 'facility'"
                tabindex="0"
              >
                Facility <span class="count">{{ facilitySearchResults ? facilitySearchResults.total : 0}}</span>
              </button>

            </div>
            <div class="tab-content-block flex flex-column">
              <div
                *ngIf="isLoading"
                class="search-result-overlay"
              >
                <div class="search-result-loader">
                  <p *ngIf="currentTab === 'provider'">Loading providers list...</p>
                  <p *ngIf="currentTab === 'facility'">Loading facility list...</p>
                  <svg
                    class="spinner"
                    width="65px"
                    height="65px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      class="path"
                      fill="none"
                      stroke-width="6"
                      stroke-linecap="round"
                      cx="33"
                      cy="33"
                      r="30"
                    ></circle>
                  </svg>
                </div>
              </div>
              <div class="tab-content flex flex-column">
                <ng-container
                  *ngIf="currentTab === 'provider'; else facilityTabContent"
                >
                  <ng-container>
                    <app-search-result-card
                      *ngFor="
                      let result of providerSearchResults.providers!
                        | paginate
                          : {
                              id: 'provider',
                              itemsPerPage: itemsPerPageProvider,
                              currentPage: currentPageProvider,
                              totalItems: providerSearchResults.total!
                            }
                    "
                      class="flex flex-column"
                      [aggregateRoot]="result"
                      type="practitioner"
                    />
                  </ng-container>
                </ng-container>

                <ng-template #facilityTabContent>
                  <!-- Content for tab 'facility' goes here -->
                  <ng-container>
                    <app-search-result-card
                      *ngFor="
                      let result of facilitySearchResults.facilities!
                        | paginate
                          : {
                              id: 'facility',
                              itemsPerPage: itemsPerPageFacility,
                              currentPage: currentPageFacility,
                              totalItems: facilitySearchResults.total!
                            }
                    "
                      class="flex flex-column"
                      [aggregateRoot]="result"
                      type="facility"
                    />
                  </ng-container>
                </ng-template>
              </div>
              <ng-container
                *ngIf="currentTab === 'provider'; else facilityPagination"
              >
                <div class="flex center align-center pagination-block">
                  <pagination-controls
                    id="provider"
                    (pageChange)="handleProviderPageChange($event)"
                  ></pagination-controls>
                  <div class="per-page-selector">
                    <label
                    >Items per page:
                      <select
                        [(ngModel)]="itemsPerPageProvider"
                        (ngModelChange)="handleProviderPageChange(1)"
                        [disabled]="isEqualWith(providerSearchResults, {})"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </select>
                    </label>
                  </div>
                </div>
              </ng-container>
              <ng-template #facilityPagination>
                <div class="flex center align-center pagination-block">
                  <pagination-controls
                    id="facility"
                    (pageChange)="handleFacilityPageChange($event)"
                  ></pagination-controls>
                  <div class="per-page-selector">
                    <label
                    >Items per page:
                      <select
                        [(ngModel)]="itemsPerPageFacility"
                        (ngModelChange)="handleFacilityPageChange(1)"
                        [disabled]="isEqualWith(providerSearchResults, {})"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                      </select>
                    </label>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </lo-container>
    </lo-container>
  </div>
</lo-container>
<div id="printSection" class="print">
  <div *ngFor="let path of imgPaths">
    <img alt="print" [src]="path">
  </div>
  <ng-container>
    <ng-container *ngIf= "currentTab === 'provider'; else facilityPrint">
      <app-print-search-results [aggregateRoot]="providerPrintResults.providers!" [printState]="printState" type="practitioner"></app-print-search-results>
    </ng-container>
    <ng-template #facilityPrint>
      <app-print-search-results [aggregateRoot]="facilityPrintResults.facilities!" [printState]="printState" type="facility"></app-print-search-results>
    </ng-template>
  </ng-container>
</div>
