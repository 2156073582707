<!--{{JSON.stringify(cardData)}}-->
<div class="search-result-card bg-white">
  <div class="provider-facility-name flex flex-wrap">
    <div class="flex flex-wrap faci-prov-name">
      <h3>{{ this.capitalizeEachWord(cardData?.extras?.Name || '') }}</h3>
      <span class="flex"> {{ cardData!.extras.Specialty }}</span>
    </div>
    <div class="bold distance">
      <span class="material-symbols-outlined">
      location_searching
      </span> Distance: {{ truncateDistance(cardData!.extras.MinDistance)}} miles
    </div>
  </div>
  <lo-container nogm class="no-margin">
    <ul class="provider-facility-address-list">
      <ng-container *ngFor="let item of getCardDataArray()">
<!--        <li-->
<!--          *ngIf="item.value"-->
<!--          [class]="item.key"-->
<!--          class="data"-->
<!--        >-->
<!--        <span class="material-symbols-outlined">-->
<!--      {{ getIconName(item.key) }}-->
<!--    </span>-->
<!--          {{ item.value }}-->
<!--        </li>-->
        <li *ngIf="item.value" [class]="item.key" class="data">
        <span class="material-symbols-outlined">
          {{ getIconName(item.key) }}
        </span>
          {{ item.key === 'Address' ? '' : item.value }}

          <!-- Display address components separately -->
          <div *ngIf="item.key === 'Address'" class="address-details">
            <div class="line">{{ item.value.line?.join(', ') }}</div>
            <div class="state-city">{{ item.value.city }}, {{ item.value.state }}</div>
            <div class="pincode">{{ item.value.postalCode }}</div>
          </div>
        </li>
      </ng-container>
    </ul>
  </lo-container>
<!--  <lo-button-->
<!--    id="accepting-new-patients"-->
<!--    variant="basic"-->
<!--    label="Accepting New Patients"-->
<!--  ></lo-button>-->
  <div class="flags-block flex">
    <span class="pills" *ngIf="aggregateRoot.relations![0].acceptingPatients?.code == 'newpt'">{{aggregateRoot.relations![0].acceptingPatients?.display}}</span>
  </div>

  <button class="show-more-details" (click)="handleShowMoreDetailsClick()" tabindex="0">
    {{ isExpanded ? "Show Less Details" : "Show More Details" }}
  </button>
  <div class="show-more-details" *ngIf="isExpanded">
    <ng-container *ngIf="loadingPrint">
      <svg class="spinner" width="15" height="15" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="4"></circle>
      </svg>
    </ng-container>
    <ng-container *ngIf="!loadingPrint && type == 'practitioner'">
      <lo-button 
        nogm 
        class="show-more-details" 
        icon="print" 
        label="Print Provider" 
        (click)="handlePrintProviderClick()" 
        tabindex="1">
      </lo-button>
    </ng-container>
    <ng-container *ngIf="!loadingPrint && type == 'facility'">
      <lo-button 
        nogm 
        class="show-more-details" 
        icon="print" 
        label="Print Facility" 
        (click)="handlePrintFacilityClick()" 
        tabindex="1">
      </lo-button>
    </ng-container>
  </div>
</div>
<div class="more-less-expand" *ngIf="isExpanded">
  <app-search-result-card-expand-details
    class="flex"
    [data]="detailsData"
    [dataType]="type"
  ></app-search-result-card-expand-details>
</div>
