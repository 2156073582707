<div class="container">
    <div class="error-container">
        <p>{{ message }}</p>
        <!-- <button onclick=returnToStart() class="button-blue">Return to Start</button> -->
        <!-- <lo-button
              [noConfig]="'true'"
              id="submit-button"
              variant="action"
              icon="filter"
              label="Return to Start"
              class="btn-bg-blue"
              (onClicked)="returnToStart()"
            >
        </lo-button> -->
    </div>
    
    
</div>
  
