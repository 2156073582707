import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import { option } from '../search-start/search-start.component';
import {
  SearchState,
  SearchStateService,
} from '../_core/services/search-state.service';
import { SearchService } from '../_core/services/search.service';
import {
  ProviderResponseOfPractitionerRoles,
  FacilityResponseOfOrgAffiliations,
  BasicProviderSearchQuery,
  BasicOrganizationSearchQuery,
} from '../_core/api/models';
import { Subject, forkJoin, takeUntil} from 'rxjs';
import {isEqualWith, isNull, omitBy} from 'lodash-es';
import { LoadingSpinnerService } from '../_core/services/loading-spinner.service';
import { CURRENT_TENNANT } from '../app.component';
import { Dropdown } from 'lo-bahamut';
import { CareFindrTenantConfigurationService } from '../_core/api/services/care-findr-tenant-configuration.service';
import {Router} from "@angular/router";
import {Checkbox} from "lo-bahamut";
import {SliderComponent} from "../_shared/slider/slider.component";
import {LocationAutocompleteComponent} from "../_shared/location-autocomplete/location-autocomplete.component";
import { ChangeDetectorRef } from '@angular/core';
import {level} from "lo-bahamut/types";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, OnDestroy, AfterViewInit {
  currentTab!: string;
  selectedSpeciality: string[]=[];
  selectedPlan: string | undefined;
  toggle : boolean = false;
  mToggle: boolean = false
  langToggle: boolean = false;
  specialtyToggle: boolean = false;
  cardTopLang: number = 0;
  cardLeftLang: number = 0;
  cardTopSpec: number = 0;
  cardLeftSpec: number = 0;
  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private searchService: SearchService,
    private searchStateService: SearchStateService,
    private tenantConfigService: CareFindrTenantConfigurationService,
    private route: Router,
    public cdRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    // Add click listener to the close buttons
    this.renderer.listen(document, 'click', (e: Event) => {
      const target = e.target as Element;

      if (target.classList.contains('close-button')) {
        e.stopPropagation(); // Stop the event from propagating
      }
    });

    // Add window click listener
    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as Node;

      if (
        target !== this.specialtyPillToggle?.nativeElement &&
        !this.specialtyPillContainer?.nativeElement.contains(target)
      ) {
        this.specialtyToggle = false;
        this.specialtyToggleIcon = 'arrow_drop_down';
      }
      if (
        target !== this.langPillToggle?.nativeElement &&
        !this.langPillContainer?.nativeElement.contains(target)
      ) {
        this.langToggle = false;
        this.langToggleIcon = 'arrow_drop_down';
      }
    });
  }
  @ViewChild('planDropdown') planDropdown!: Dropdown;
  @ViewChild('languageDropdown') languageDropdown!: Dropdown;
  @ViewChild('genderDropdown') genderDropdown!: Dropdown;

  @ViewChild('aNPChecked') aNPChecked: Checkbox;
  @ViewChild('hAChecked') hAChecked!: Checkbox;
  @ViewChild('pCPChecked') pCPChecked!: Checkbox;

  @ViewChild('sliderComponent') sliderComponent!: SliderComponent

  @ViewChild('locationAutocomplete') locationAutocomplete!: LocationAutocompleteComponent

  @ViewChild('specialtyDropdown') specialtyDropdown: Dropdown;

  @ViewChild('specialtyPillToggle') specialtyPillToggle: ElementRef<HTMLElement>;
  @ViewChild('specialtyPillContainer') specialtyPillContainer: ElementRef<HTMLElement>;
  @ViewChild('langPillToggle') langPillToggle: ElementRef<HTMLElement>;
  @ViewChild('langPillContainer') langPillContainer: ElementRef<HTMLElement>;

  isEqualWith = isEqualWith;
  debounceDelay = 300;
  destroyed$ = new Subject<void>();
  searchDistance = '20';

  itemsPerPageProvider: number = 5;
  currentPageProvider: number = 1;

  itemsPerPageFacility: number = 5;
  currentPageFacility: number = 1;
  langToggleIcon: string = 'arrow_drop_down';
  specialtyToggleIcon: string = 'arrow_drop_down';

  planOptions: option[] = [];
  specialtyOptions: level[] = [];
  sortOptions: option[] = [];
  genderOptions: option[] = [];
  languageOptions: option[] = [];

  providerSearchResults: ProviderResponseOfPractitionerRoles = {};
  facilitySearchResults: FacilityResponseOfOrgAffiliations = {};
  providerPrintResults: ProviderResponseOfPractitionerRoles = {};
  facilityPrintResults: FacilityResponseOfOrgAffiliations = {};

  searchState: SearchState = {
    Query: {},
    Extra: {},
  };
  printState: SearchState = {
    Query: {},
    Extra: {},
  };

  specialty: string = '';
  printProviderReady: boolean = false;
  printFacilityReady: boolean = false;

  imgPaths: string[] = [];

  isANPChecked: "true" | undefined;
  isHAChecked: "true" | undefined;
  isPCPChecked: "true" | undefined;

  defaultSlideValue: number | undefined = 25;

  /* variables to show selected filters */
  planValue: string | undefined;
  slidedValue: number | undefined;
  langValue: string[] = [];
  genderValue!: string | undefined;
  acpValue!: string | undefined;
  haValue!: string | undefined;
  pcpValue!: string | undefined;
  locationValue!: string | undefined;
  nameValue!: string | undefined;
  specialtyValue!: string | undefined;

  isLoading: boolean = false;
  isNameLength!: boolean;

  isPlanDropDownEnabled = true;

  selectedSpecialtyDisplay: string[] = [];
  selectedSpecialtyValue: string[] = [];
  isSpecialityHeaderNeedToChange = false;

  ngOnInit() {
    //TODO: To be revised after configuration driven UI is implemented
    if(CURRENT_TENNANT === 'centerlight' || CURRENT_TENNANT === 'mddoh')
      this.isPlanDropDownEnabled = false;

    //TODO: To be revised after configuration driven UI is implemented
    if(CURRENT_TENNANT === 'mddoh')
      this.isSpecialityHeaderNeedToChange = true;

    //get existing results and search state passed from search-start
    this.providerSearchResults = this.searchService.getCurrentProviderResults();
    this.facilitySearchResults = this.searchService.getCurrentFacilityResults();
    if(this.providerSearchResults?.total! == 0 && this.facilitySearchResults?.total! > 0){
      this.currentTab = 'facility';
    } else{
      this.currentTab = 'provider';
    }
    this.searchState = this.searchStateService.getSearchCriteria();
    this.printState = this.searchStateService.getSearchCriteria();

    this.buildPreface()

    this.searchService.providerSearchResults$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((results) => {
        this.providerSearchResults = {...results};
      });

    this.searchService.facilitySearchResults$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((results) => {
        this.facilitySearchResults = {...results};
      });

    this.searchService.providerPrintResults$
      .pipe(
        takeUntil(this.destroyed$)
        )
      .subscribe((results) => {
        this.providerPrintResults = {...results};

        if(this.providerPrintResults.providers && (this.providerPrintResults.providers!.length > 0 || this.providerPrintResults.total == 0)) {
          this.printProviderReady = true;
        }
      });

    this.searchService.facilityPrintResults$
      .pipe(
        takeUntil(this.destroyed$)
        )
      .subscribe((results) => {
        this.facilityPrintResults = {...results};

        if(this.facilityPrintResults.facilities && (this.facilityPrintResults.facilities!.length > 0 || this.facilityPrintResults.total == 0)) {
          this.printFacilityReady = true;
        }
      });

    this.searchStateService.searchState$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state) => {
        this.searchState = state;
      });

    this.tenantConfigService
      .getTenantComponentJs({tenant: CURRENT_TENNANT})
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.specialtyOptions = response.specialtyDropdown?.options?.map((option) => {
            return {
              value: option.value as string,
              description: option.description as string,
              children: option.children as level[]
            }
          }).sort((a,b) => {
            return a.description!.localeCompare(b.description!)
          }) as level[]

          this.planOptions = response.planDropdown?.options as option[];
          this.languageOptions = response.languageDropdown?.options as option[];
          this.genderOptions = response.genderDropdown?.options as option[];
          this.sortOptions = response.sortDropdown?.options as option[];

          this.cdRef.detectChanges()

          if(this.planDropdown){
            this.selectedPlan = this.searchState.Query.programType;
            this.planDropdown.data = this.selectedPlan ? [this.selectedPlan] : [];
            this.planDropdown.selected = this.selectedPlan ? [this.selectedPlan] : [];
            this.planValue = this.selectedPlan;
          }

          if('specialtiesV2' in this.searchState.Query){
            this.setSpecialtyFilters(this.searchState.Query.specialtiesV2!);
            this.specialtyDropdown.data = this.searchState.Query.specialtiesV2!
            this.selectedSpecialtyValue = this.specialtyDropdown.data
          }

        }
      })

    this.locationValue = this.searchState.Extra.locationText?? undefined;
    this.nameValue = this.searchState.Query.name;
    this.planValue = this.searchState.Query.programType;
    //this.specialtyValue = this.searchState.Query.specialties?.[0].specialtyDisplay;
    if(this.searchState.Query.location?.distance){
      this.defaultSlideValue = Number(this.searchState.Query.location?.distance);
      this.slidedValue = this.defaultSlideValue;
    }


    // const dropdownSelectedPlan = this.planOptions.find(obj => obj.value === this.searchState.Query.programType)
    // if(this.searchState.Query.programType){
    //   this.planValue =this.searchState.Query.programType;
    // }

    // Use filter to get descriptions of multi-selected values
    const selectedLanguage = this.languageOptions
      .filter(item => this.searchState.Query.language?.includes(item.value))
      .map(item => item.description);
    if(selectedLanguage){
      this.langValue = selectedLanguage;
      this.langValue = Array.from(new Set(this.langValue));
    } else {
      this.langValue = [];
    }
    this.langToggle = false;
  }

  ngAfterViewInit() {
    this.sliderComponent.distanceOption.data = this.searchState.Query.location?.distance ? [this.searchState.Query.location?.distance] : ['25'];
    // this.lodropdown.data = this.searchState.Extra.testMulti;
    // this.sliderComponent.distanceOption.data= this.searchState.Query.location?.distance
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.searchStateService.clearSearchCriteria();
  }

  switchTab(tab: string): void {
    this.currentTab = tab;
  }

  handleSearchClick() {
    this.printProviderReady = false
    this.printFacilityReady = false
    this.printState = this.searchState;
    this.facilityPrintResults = {}
    this.providerPrintResults = {}
    this.currentPageProvider = 1;
    this.currentPageFacility = 1;
    const providerQueryParams: BasicProviderSearchQuery = {
      ...omitBy(this.searchStateService.getSearchCriteria().Query, isNull),
      skip: 0,
      limit: this.itemsPerPageProvider,
    };

    const facilityQueryParams: BasicOrganizationSearchQuery = {
      ...omitBy(this.searchStateService.getSearchCriteria().Query, isNull),
      skip: 0,
      limit: this.itemsPerPageFacility,
    };

    // Use filter to get descriptions of multi-selected values
    const selectedLanguage = this.languageOptions
      .filter(item => this.searchState.Query.language?.includes(item.value))
      .map(item => item.description);
    if(selectedLanguage){
      this.langValue = selectedLanguage;
      this.langValue = Array.from(new Set(this.langValue));
    } else {
      this.langValue = [];
    }

    const providerPrintParams: BasicProviderSearchQuery = {
      ...omitBy(this.printState.Query, isNull),
      skip: 0,
      limit: 200,
    }

    const facilityPrintParams: BasicOrganizationSearchQuery = {
      ...omitBy(this.printState.Query, isNull),
      skip: 0,
      limit: 200,
    }

    //only navigate to search-results after both calls return with a response
    // this.loadingSpinnerService.showLoader();
    this.isLoading = true;
    forkJoin({
      providerResults:
        this.searchService.getProviderResults(providerQueryParams),
      facilityResults:
        this.searchService.getFacilityResults(facilityQueryParams),
    }).subscribe({
      next: (results) => {
        this.providerSearchResults = {...results.providerResults};
        this.facilitySearchResults = {...results.facilityResults};
        if(this.providerSearchResults?.total! == 0 && this.facilitySearchResults?.total! > 0){
          this.currentTab = 'facility';
        } else{
          this.currentTab = 'provider';
        }

      },
      error: (err) => {
        console.log('An error occurred:', err);
      },
      complete: () => {
        // this.loadingSpinnerService.hideLoader();
        this.isLoading = false;
      },
    });

    forkJoin({
      providerPrint:
        this.searchService.getProviderResults(providerPrintParams, true),
      facilityPrint:
        this.searchService.getFacilityResults(facilityPrintParams, true),
    }).subscribe({
      next: (results) => {
        this.facilityPrintResults = {...results.facilityPrint};
        this.providerPrintResults = {...results.providerPrint};
        this.printProviderReady = true;
        this.printFacilityReady = true;
      },
      error: (err) => {
        console.log('An error occured:', err)
      }
    });
  }

  handleFilterSearch(){
    this.handleSearchClick()
    this.filterToggle('');
  }

  handleProviderPageChange(pageNum: number) {
    this.currentPageProvider = pageNum;
    const skip = (this.currentPageProvider - 1) * this.itemsPerPageProvider;

    const queryParams = {
      ...omitBy(this.searchStateService.getSearchCriteria().Query, isNull),
      skip: skip,
      limit: this.itemsPerPageProvider,
    };

    // this.loadingSpinnerService.showLoader();
    this.isLoading = true;
    this.searchService.getProviderResults(queryParams).subscribe({
      next: (results) => {
        this.providerSearchResults = results;
      },
      complete: () => {
        // this.loadingSpinnerService.hideLoader();
        this.isLoading = false;
      },
    });
  }

  handleFacilityPageChange(pageNum: number) {
    this.currentPageFacility = pageNum;
    const skip = (this.currentPageFacility - 1) * this.itemsPerPageFacility;

    const queryParams = {
      ...omitBy(this.searchStateService.getSearchCriteria().Query, isNull),
      skip: skip,
      limit: this.itemsPerPageFacility,
    };

    // this.loadingSpinnerService.showLoader();
    this.isLoading = true;
    this.searchService.getFacilityResults(queryParams).subscribe({
      next: (results) => {
        this.facilitySearchResults = results;
      },
      complete: () => {
        // this.loadingSpinnerService.hideLoader();
        this.isLoading = false;
      },
    });
  }

  handleNameInput(value: string) {
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, name: value },
    });
    if(this.searchState.Query.name != '' || undefined){
      this.nameValue = this.searchState.Query.name;
    }else{
      this.nameValue = undefined;
    }
    if(value.length >1){
      this.isNameLength = true;
      this.isSearchEnabled();
    }else{
      this.isNameLength = false;
    }
  }

  handleSpecialtyDropdown(value: string[]) {
    if(value.length > 0){
      const specialties = value.map(specialtyString => JSON.parse(specialtyString));
      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          specialties: specialties,
        },
      });
      this.specialtyValue = this.searchState.Query.specialties?.[0].specialtyDisplay;
    }else{
      this.specialtyValue= undefined;
    }
    this.isSearchEnabled();
  }

  setSpecialtyFilters(values: string[]) {
    let selectedDescriptions: string[] = []

    // Recursive function to find descriptions from children
    const findDescriptions = (items: any[]) => {
      for (const item of items) {
        // Check if the current item value is in the input values array
        if (values.includes(item.value)) {
          // Add the description to the selected descriptions array
          selectedDescriptions.push(item.description);
        }
        // Check if the current item has children
        if (item.children && item.children.length > 0) {
          // Recursively call the function for children
          findDescriptions(item.children);
        }
      }
    };

    // Call the recursive function to find descriptions
    findDescriptions(this.specialtyOptions);

    // Check for values not found in the levelsTest and add them to the selected descriptions array
    for (const value of values) {
      const found = this.specialtyOptions.some((item:level) => item.value === value || (item.children && item.children.some((child:level) => child.value === value)));
      if (!found) {
        selectedDescriptions.push(value);
      }
    }

    // Return unique descriptions
    this.selectedSpecialtyDisplay = Array.from(new Set(selectedDescriptions));
  }

  handleTreeSpecialtyDropdown(values: string[]) {
    if(values && this.specialtyDropdown){
      this.setSpecialtyFilters(values)
      this.selectedSpecialtyValue = values;
      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          specialtiesV2: this.specialtyDropdown.data,
        },
      });
      this.isSearchEnabled();
    }
  }

  deselectSpecialty(index: number) {
    if (index >= 0 && index < this.selectedSpecialtyDisplay.length) {
      this.selectedSpecialtyDisplay.splice(index, 1);
      this.selectedSpecialtyDisplay = Array.from(new Set(this.selectedSpecialtyDisplay));
      const specialties = this.selectedSpecialtyDisplay
      //   .filter(item => this.selectedTreeValue.includes(item.description))
      //   .map(item => item.value);
      // this.searchStateService.updateSearchCriteria({
      //   Query: { ...this.searchState.Query, specialty: specialties },
      // });
      this.specialtyDropdown.data = this.selectedSpecialtyDisplay.map((display) => {
        for(const mapping of this.specialtyOptions){
          if(mapping.description == display){
            return mapping.value;
          }

          for(const child of mapping.children){
            if(child.description == display){
              return child.value;
            }
          }
        }
        return null;
      }).filter(value => value !== null) as string[];

      this.selectedSpecialtyValue = this.specialtyDropdown.data;

      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          specialtiesV2: this.specialtyDropdown.data,
        },
      });

      this.isSearchEnabled();
      this.cdRef.detectChanges();
    }
  }

  handlePlanDropdown(value: string[]) {
    if (value[0] != 'default' ) {
      const programTypeString = value[0]; // Join array elements into a single string
      this.searchStateService.updateSearchCriteria({
        Query: { ...this.searchState.Query, programType: programTypeString },
      });
      // const selectedPlan = this.planOptions.find(obj => obj.value === programTypeString);
        this.planValue = value[0];
    } else {
      this.planValue = undefined;
    }
    this.isSearchEnabled();
  }

  handleLanguageDropdown(values: string[]) {
    // const language = value.join(',')
    // Use filter to get descriptions of multi-selected values
    const selectedLanguage = this.languageOptions
      .filter(item => values.includes(item.description))
      .map(item => item.value);
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, language: selectedLanguage },
    });
    this.langValue = Array.from(new Set(values));

    this.isSearchEnabled()
  }

  handleGenderDropdown(value: string[]) {
    const gender = value.join(',');
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, gender: gender },
    });
      if(value){
        this.genderValue = gender;
      }else{
        this.genderValue = undefined;
      }
    this.isSearchEnabled()
  }

  handleSliderValueChange(value: string[]) {
    const distValue = value[0];
    if (distValue) {
      this.searchStateService.updateSearchCriteria({
        Query: {
          ...this.searchState.Query,
          location: {
            ...this.searchState.Query.location,
            distance: distValue.toString(),
          },
        },
      });
      this.slidedValue = parseInt(value[0]);
      this.isSearchEnabled();
    }
  }


  handleAcceptingNewPatient(value: any){
    const isChecked = this.aNPChecked.checked ? true : undefined;
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, isAcceptingNewPatient: isChecked}
    })
    if(isChecked){
      this.isANPChecked = 'true';
      this.acpValue = "Accepting New Patients";
    }else{
      this.isANPChecked = undefined;
    }
    this.isSearchEnabled();
  }
  handleHandicapAccessible(value: any){
    const isChecked = this.hAChecked.checked ? true : undefined;
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, isHandicapAccessible: isChecked}
    })
    if(isChecked){
    this.isHAChecked = 'true';
      this.haValue= "Handicap Accessible";
    }else{
      this.isHAChecked = undefined;
    }
    this.isSearchEnabled();
  }
  handlePCP(value: any){
    const isChecked = this.pCPChecked.checked ? true: undefined;
    this.searchStateService.updateSearchCriteria({
      Query: { ...this.searchState.Query, isPCP: isChecked}
    });
    if(isChecked){
    this.isPCPChecked = 'true';
    this.pcpValue = "Primary Care Provider (PCP)";
    }else{
      this.isPCPChecked = undefined;
    }
    this.isSearchEnabled();
  }

  getLocationText(event: any){
    document.getElementById('location-input')?.focus()
    this.locationValue = event;
    this.isSearchEnabled();
  }

  clearSearchInputs() {
    // this.searchStateService.clearSearchCriteria();
    if (this.searchState.Query && this.searchState.Query.location) {
      this.searchState.Query.location.latitude = undefined;
      this.searchState.Query.location.longitude = undefined;
    }
    this.locationAutocomplete.inputField.nativeElement.value = '';
    this.searchState.Query.name = undefined;
    this.searchState.Query.specialties = undefined;
  }

  isSearchButtonEnabled(): boolean {
    return this.searchState.Extra.locationText != undefined &&
          (this.searchState.Query.name != undefined ||
          (this.searchState.Query.specialties != undefined &&
          this.searchState.Query.specialties.length > 0))
  }
  isSearchEnabled() {
    const isFormValid =
     typeof(this.searchState.Extra.locationText) != 'undefined' &&
          (typeof(this.searchState.Query.name) != "undefined" && this.isNameLength ||
          (typeof(this.searchState.Query.specialtiesV2) != "undefined" &&
          this.searchState.Query.specialtiesV2.length > 0));
    if(isFormValid){
      this.handleSearchClick()
    }
    else{
      console.log( "========== validation is working ========")
    }
  }

  checkNameSpecialty(){
    let isRequired: boolean= false;
    if(this.specialtyDropdown){
      if(this.nameValue && this.selectedSpecialtyValue.length >= 1){
        isRequired = false;
      }else if((this.nameValue || this.selectedSpecialtyValue.length == 1) && !(this.nameValue && this.selectedSpecialtyValue.length == 1)){
        isRequired = true;
      }
      return isRequired
    }
    return isRequired
  }

  closeSelectedFilters(event: any){
    console.log('anp =' +this.aNPChecked.checked)
    console.log('hac =' +this.hAChecked.checked)
    console.log('pcp =' +this.pCPChecked.checked)

    // this.aNPChecked.checked = !this.aNPChecked.checked;
    // this.hAChecked.checked = !this.hAChecked.checked;
    // this.pCPChecked.checked = !this.pCPChecked.checked;

    //
    // console.log('after anp =' +this.aNPChecked.checked)
    // console.log('after hac =' +this.hAChecked.checked)
    // console.log('after pcp =' +this.pCPChecked.checked)
    switch(event) {
      case this.locationValue:
        this.locationValue = undefined;
        if (this.searchState.Query && this.searchState.Query.location) {
          this.searchState.Query.location.latitude = undefined;
          this.searchState.Query.location.longitude = undefined;
        }
        this.searchState.Extra.locationText = undefined;
        break;
      case this.nameValue:
        this.nameValue = undefined;
        this.searchState.Query.name = undefined;
        break;
      case this.specialtyValue:
        this.specialtyValue = undefined;
        this.searchState.Query.specialties = undefined;
        break;
      case this.slidedValue:
        this.slidedValue = undefined;
        if (this.searchState.Query && this.searchState.Query.location) {
          this.searchState.Query.location.distance = undefined;
        }
        this.defaultSlideValue = 25;
        this.sliderComponent.selectedValue = this.defaultSlideValue;
        break;
      case this.searchState.Query.language:
        this.languageDropdown.data = [""];
        this.searchState.Query.language = undefined;
        break;
      case this.genderValue:
        this.genderDropdown.data = [""];
        if ("gender" in this.searchState.Query) {
          this.searchState.Query.gender = undefined;
        }
        break;
      case this.isANPChecked:
        this.aNPChecked.checked = !this.aNPChecked.checked;
        this.searchState.Query.isAcceptingNewPatient = undefined;
        this.isANPChecked = undefined;
        // this.aNpChecked.checked = undefined;
        break;
      case this.isHAChecked:
        this.searchState.Query.isHandicapAccessible = undefined;
        this.isHAChecked= undefined;
        this.hAChecked.checked = !this.hAChecked.checked;
        break;
      case this.isPCPChecked:
        this.pCPChecked.checked = !this.pCPChecked.checked;
        if ("isPCP" in this.searchState.Query) {
          this.searchState.Query.isPCP = undefined;
        }
        this.isPCPChecked = undefined;
        break;
      default:
        break;
    }
    this.isSearchEnabled();
    this.cdRef.detectChanges();
  }

  deselectLang(index: number) {
    if (index >= 0 && index < this.langValue.length) {
      this.langValue.splice(index, 1);
      this.langValue = Array.from(new Set(this.langValue));
      const languages = this.languageOptions
        .filter(item => this.langValue.includes(item.description))
        .map(item => item.value);
      this.searchStateService.updateSearchCriteria({
        Query: { ...this.searchState.Query, language: languages },
      });
      this.languageDropdown.data = languages;
      this.isSearchEnabled();
      this.cdRef.detectChanges();
    }
  }

  clearFilterInputs(){
    // if (this.searchState.Query && this.searchState.Query.location) {
    //   this.searchState.Query.location.distance = undefined;
    // }
    // this.defaultSlideValue = 25;
    this.languageDropdown.data = [];
    this.languageDropdown.ddFilterInput.nativeElement.value='';
    this.languageDropdown.options = this.languageOptions;
    this.genderDropdown.data = [];
    this.searchState.Query.language =undefined;
    if ("gender" in this.searchState.Query) {
      this.searchState.Query.gender = undefined;
    }
    this.searchState.Query.isAcceptingNewPatient = undefined;
    this.searchState.Query.isHandicapAccessible = undefined;
    if ("isPCP" in this.searchState.Query) {
      this.searchState.Query.isPCP = undefined;
    }
    this.aNPChecked.checked = this.aNPChecked.checked?!this.aNPChecked.checked:this.aNPChecked.checked;
    this.hAChecked.checked = this.hAChecked.checked?!this.hAChecked.checked:this.hAChecked.checked;
    this.pCPChecked.checked = this.pCPChecked.checked?!this.pCPChecked.checked:this.pCPChecked.checked;
    this.isANPChecked = undefined;
    this.isHAChecked = undefined;
    this.isPCPChecked = undefined;
    this.genderValue = undefined;
    this.isSearchEnabled()
  }

  printReady(): boolean {
    if(this.currentTab === 'provider') {
      return this.printProviderReady;
    }
    return this.printFacilityReady;
  }

  print() {
    //TODO: To be revised after configuration driven UI is implemented
    if (CURRENT_TENNANT == 'mddoh'){
      document.title = "Maryland Medicaid Provider Finder";
    }
    window.print();
  }

  buildPreface() {
    let folderPath = '../../assets/preface-' + CURRENT_TENNANT + '/'

    if(CURRENT_TENNANT === 'riverspring') [
      this.imgPaths = [
        folderPath + 'page1.png',
        folderPath + 'page2.png',
        folderPath + 'page3.png',
        folderPath + 'page4.png',
        folderPath + 'page5.png'
      ]
    ]

  }

  filterToggle(event: any){
    this.toggle = !this.toggle;
  }
  menuToggle(event: any){
    this.mToggle = !this.mToggle
  }

  showCombinedLang(event: MouseEvent) {
    this.langToggle = !this.langToggle;
    if (this.langToggle) {
      this.langToggleIcon = 'arrow_outward';
      // Get the clicked element
      const clickedElement = event.target as HTMLElement;
      // Get its parent element
      const parentElement = clickedElement.parentElement;
      if (parentElement) {
        // Calculate position of card relative to the parent element
        const rect = parentElement.getBoundingClientRect();
        // Calculate cardTop to position the card below the parent element
        this.cardTopLang = rect.bottom;
        // Calculate cardLeft to align the card with the left edge of the parent element
        this.cardLeftLang = rect.left - 15;
      }
    } else {
      this.langToggleIcon = 'arrow_drop_down';
    }
  }
  showCombinedSpecialty(event: MouseEvent) {
    this.specialtyToggle = !this.specialtyToggle;
    if (this.specialtyToggle) {
      this.specialtyToggleIcon = 'arrow_outward';
      // Get the clicked element
      const clickedElement = event.target as HTMLElement;
      // Get its parent element
      const parentElement = clickedElement.parentElement;
      if (parentElement) {
        // Calculate position of card relative to the parent element
        const rect = parentElement.getBoundingClientRect();
        // Calculate cardTop to position the card below the parent element
        this.cardTopSpec = rect.bottom;
        // Calculate cardLeft to align the card with the left edge of the parent element
        this.cardLeftSpec = rect.left - 15;
      }
    } else {
      this.specialtyToggleIcon = 'arrow_drop_down';
    }
  }

  removeSpecialtyPills(){
    this.selectedSpecialtyDisplay = [];
    this.searchState.Query.specialtiesV2 = undefined;
    this.specialtyDropdown.data = [];
    this.specialtyDropdown.optionClicked('');
  }

  removeLanguagePills(){
    this.langValue = [];
    this.languageDropdown.data = [];
    this.languageDropdown.ddFilterInput.nativeElement.value='';
    this.languageDropdown.options = this.languageOptions;
    this.searchState.Query.language =undefined;
  }
}
