import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './_core/core.module';
import { SearchStartModule } from './search-start/search-start.module';
import { SearchResultsModule } from './search-results/search-results.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { GoogleApiService } from './_core/services/google-api.service';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { ActionPasser, Utils, Logger } from "lo-bahamut";

@NgModule({
  declarations: [AppComponent, ErrorHandlerComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    SearchStartModule,
    SearchResultsModule,
    FormsModule,
  ],
  providers: [
    ActionPasser,
    Utils,
    Logger,
    GoogleApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: (apiService: GoogleApiService) => () =>
        apiService.initializeGoogleApi(),
      deps: [GoogleApiService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
