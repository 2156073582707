import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SearchStartComponent } from './search-start/search-start.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { ErrorHandlerComponent  } from "./error-handler/error-handler.component";

const routes: Routes = [
  {
    path: 'home',
    component: SearchStartComponent,
  },
  {
    path: '',
    component: SearchStartComponent,
  },
  {
    path: 'search-results',
    component: SearchResultsComponent,
  },
  {
    path: 'error-handler',
    component: ErrorHandlerComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
