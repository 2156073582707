import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultsComponent } from './search-results.component';
// import { BahamutModule } from '../bahamut.module';
import { SearchResultCardModule } from './search-result-card/search-result-card.module';
import { SharedModule } from '../_shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { PrintSearchResultsModule } from './print-search-results/print-search-results.module';
import {Button, Checkbox, Container, Dropdown, InputLo} from "lo-bahamut";

@NgModule({
  declarations: [SearchResultsComponent],
    imports: [
        CommonModule,
        // BahamutModule,
        SharedModule,
        SearchResultCardModule,
        NgxPaginationModule,
        FormsModule,
        PrintSearchResultsModule,
        Dropdown,
        InputLo,
        Container,
        Checkbox,
        Button
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultsModule {}
