<div class="container">
  <div class="content">
    <img
      class="logo"
      src="../../assets/Convergent_logo_orgblu.png"
      alt="Company Logo"
    />
    <h1>Coming Soon</h1>
  </div>
</div>
<div class="wave-background"></div>
