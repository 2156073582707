<div class="result-expand-detail-tab-block flex bg-white">
  <div class="detail-tab-nav flex">
    <button
      class="detail-tab-nav-item"
      (click)="switchTab('tab1')"
      [class.active]="currentTab === 'tab1'"
      tabindex="0"
    >
      {{ tab1Name }}
    </button>
    <button
      class="detail-tab-nav-item"
      (click)="switchTab('tab2')"
      [class.active]="currentTab === 'tab2'"
      tabindex="0"
    >
      Location Information
    </button>
<!--    <div-->
<!--      class="detail-tab-nav-item"-->
<!--      (click)="switchTab('tab3')"-->
<!--      [class.active]="currentTab === 'tab3'"-->
<!--    >-->
<!--      Insurance Accepted-->
<!--    </div>-->
  </div>
  <lo-container nogm class="detail-tab-content-block no-margin">
    <ng-container *ngIf="currentTab === 'tab1'">
      <app-search-result-card-expand-tab1
        [detailRoot]="data"
        [dataType]="dataType"
      ></app-search-result-card-expand-tab1>
    </ng-container>
    <ng-container *ngIf="currentTab === 'tab2'">
      <app-search-result-card-expand-tab2
        [relationsGroupedByLocation]="relationsGroupedByLocation"
      ></app-search-result-card-expand-tab2>
    </ng-container>
    <ng-container *ngIf="currentTab === 'tab3'">
      <app-search-result-card-expand-tab3
        [relationsGroupedByLocation]="relationsGroupedByLocation"
        [dataType]="dataType"
      ></app-search-result-card-expand-tab3>
    </ng-container>
  </lo-container>
</div>
