import { Component, Input, OnInit } from '@angular/core';
import {
  FacilityResponseOfMasteredLocations,
  OrganizationAffiliationsGroupedByLocation,
  PractitionerRolesGroupedByLocation,
  ProviderResponseOfMasteredLocations,
} from 'src/app/_core/api/models';

@Component({
  selector: 'app-search-result-card-expand-details',
  templateUrl: './search-result-card-expand-details.component.html',
  styleUrls: ['./search-result-card-expand-details.component.scss'],
})
export class SearchResultCardExpandDetailsComponent implements OnInit {
  tab1Name!: string;
  @Input() data!:
    | ProviderResponseOfMasteredLocations
    | FacilityResponseOfMasteredLocations;

  @Input() dataType!: 'practitioner' | 'facility';

  currentTab: string = 'tab2';
  relationsGroupedByLocation!:
    | OrganizationAffiliationsGroupedByLocation[]
    | PractitionerRolesGroupedByLocation[];

  ngOnInit(): void {
    if (this.dataType === 'facility') {
      this.tab1Name = 'Facility Information';
      const curr_data = this.data as FacilityResponseOfMasteredLocations;
      this.relationsGroupedByLocation = curr_data.facilities![0].relations!;
    } else if (this.dataType === 'practitioner') {
      this.tab1Name = 'Provider Information';
      const curr_data = this.data as ProviderResponseOfMasteredLocations;
      this.relationsGroupedByLocation = curr_data.providers![0].relations!;
    }
  }

  switchTab(tab: string) {
    this.currentTab = tab;
  }
}
