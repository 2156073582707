import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultCardComponent } from './search-result-card.component';
// import { BahamutModule } from '../../bahamut.module';
import { SearchResultCardExpandDetailsModule } from './search-result-card-expand-details/search-result-card-expand-details.module';
import {Button} from "lo-bahamut";

@NgModule({
  declarations: [SearchResultCardComponent],
    imports: [CommonModule,
        // BahamutModule,
        SearchResultCardExpandDetailsModule, Button],
  exports: [SearchResultCardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultCardModule {}
