import { Component, Input, OnInit } from '@angular/core';
import { OrganizationAffiliationsGroupedByLocation } from '../../../../_core/api/models/organization-affiliations-grouped-by-location';
import { PractitionerRolesGroupedByLocation } from '../../../../_core/api/models/practitioner-roles-grouped-by-location';
import {
  SearchResultCardExpandTab3AdapterService,
  Tab3Data,
} from '../../../../_core/services/searchResultCard/search-result-card-expand-tab3-adapter.service';

@Component({
  selector: 'app-search-result-card-expand-tab3',
  templateUrl: './search-result-card-expand-tab3.component.html',
  styleUrls: ['./search-result-card-expand-tab3.component.scss'],
})
export class SearchResultCardExpandTab3Component implements OnInit {
  cardData!: Tab3Data[];
  currentIndex = 0;
  @Input() dataType!: 'practitioner' | 'facility';
  @Input() relationsGroupedByLocation!:
    | OrganizationAffiliationsGroupedByLocation[]
    | PractitionerRolesGroupedByLocation[];

  constructor(
    private searchResultCardExpandTab3AdapterService: SearchResultCardExpandTab3AdapterService
  ) {}
  ngOnInit() {
    if (this.dataType === 'practitioner') {
      this.cardData =
        this.searchResultCardExpandTab3AdapterService.adaptProvider(
          this
            .relationsGroupedByLocation as PractitionerRolesGroupedByLocation[]
        );
    } else if (this.dataType === 'facility') {
      this.cardData =
        this.searchResultCardExpandTab3AdapterService.adaptFacility(
          this
            .relationsGroupedByLocation as OrganizationAffiliationsGroupedByLocation[]
        );
    }
  }
  currentActive(value: number) {
    this.currentIndex = value;
  }

  protected readonly Object = Object;
}
