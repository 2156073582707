import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerService } from './_core/services/loading-spinner.service';
import { FeatureFlagService } from './_core/services/feature-flag.service';
import { find } from 'lodash-es';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private featureFlagService: FeatureFlagService,
    public loadingSpinnerService: LoadingSpinnerService
  ) {}
  title = 'CareFinDr';

  ngOnInit() {
    this.featureFlagService.initFeatureFlags();
  }
}

function getTenantFromUrl(): string {
  const hostname = window.location.hostname;
  // Examples:
  //let hostname = "stg-wahbe.carefindr.getconvergent.io"
  //let hostname = "wahbe.carefindr.getconvergent.io"

  if (hostname === 'localhost') {
    return 'centerlight';
    // return 'riverspring';
    // return 'mddoh';
  }

  const parts = hostname.split('.');
  let tenant = parts[0].toUpperCase();

  // Remove environment prefix if present
  const envPrefix = find(['DEV', 'TST', 'STG'], (prefix) =>
    tenant.startsWith(`${prefix}-`)
  );
  return (envPrefix ? tenant.replace(`${envPrefix}-`, '') : tenant).toLowerCase();
}
export const CURRENT_TENNANT = getTenantFromUrl();
