/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WahbeDoctorDetailResponse } from '../models/wahbe-doctor-detail-response';
import { WahbeDoctorSearchResponse } from '../models/wahbe-doctor-search-response';
import { WahbeFacilitySearchResponse } from '../models/wahbe-facility-search-response';

@Injectable({
  providedIn: 'root',
})
export class WashingtonHealthBenefitExchangeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation wahbePracticeSearch
   */
  static readonly WahbePracticeSearchPath = '/wahbe/practices';

  /**
   * Practices Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making<br/>a HTTPS GET Call. This interface is invoked when the user has toggled to the facility radio option and clicked on search on the expanded search<br/>screen.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbePracticeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbePracticeSearch$Response(params: {

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF. <br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeFacilitySearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbePracticeSearchPath, 'get');
    if (params) {
      rb.query('location', params.location, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('name', params.name, {});
      rb.query('specialty_uid', params.specialty_uid, {});
      rb.query('insurance_program_type', params.insurance_program_type, {});
      rb.query('plan_year', params.plan_year, {});
      rb.query('language', params.language, {});
      rb.query('explain', params.explain, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeFacilitySearchResponse>;
      })
    );
  }

  /**
   * Practices Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making<br/>a HTTPS GET Call. This interface is invoked when the user has toggled to the facility radio option and clicked on search on the expanded search<br/>screen.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbePracticeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbePracticeSearch(params: {

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF. <br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeFacilitySearchResponse> {

    return this.wahbePracticeSearch$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeFacilitySearchResponse>) => r.body as WahbeFacilitySearchResponse)
    );
  }

  /**
   * Path part for operation wahbePracticeDetail
   */
  static readonly WahbePracticeDetailPath = '/wahbe/practices/{uid}';

  /**
   * Organization Affiliation Detail Request.
   *
   * Retrieve facility detail information by the unique ID (uid) supplied in the search view.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbePracticeDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbePracticeDetail$Response(params: {

    /**
     * The UID of the Organization Affiliation
     */
    uid: string;

    /**
     * Specify the type of the UID, only support `npi` (case senstive) for now.
     */
    type?: string;

    /**
     * Set to true to turn on debugging
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeFacilitySearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbePracticeDetailPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.query('type', params.type, {});
      rb.query('explain', params.explain, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
      rb.header('x-user-id', params['x-user-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeFacilitySearchResponse>;
      })
    );
  }

  /**
   * Organization Affiliation Detail Request.
   *
   * Retrieve facility detail information by the unique ID (uid) supplied in the search view.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbePracticeDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbePracticeDetail(params: {

    /**
     * The UID of the Organization Affiliation
     */
    uid: string;

    /**
     * Specify the type of the UID, only support `npi` (case senstive) for now.
     */
    type?: string;

    /**
     * Set to true to turn on debugging
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeFacilitySearchResponse> {

    return this.wahbePracticeDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeFacilitySearchResponse>) => r.body as WahbeFacilitySearchResponse)
    );
  }

  /**
   * Path part for operation wahbePracticeSearchFuture
   */
  static readonly WahbePracticeSearchFuturePath = '/wahbe/practices-future';

  /**
   * Organization Affiliation Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making<br/>a HTTPS GET Call. This interface is invoked when the user has toggled to the facility radio option and clicked on search on the expanded search<br/>screen.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbePracticeSearchFuture()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  wahbePracticeSearchFuture$Response(params: {

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF. <br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeFacilitySearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbePracticeSearchFuturePath, 'get');
    if (params) {
      rb.query('location', params.location, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('name', params.name, {});
      rb.query('specialty_uid', params.specialty_uid, {});
      rb.query('insurance_program_type', params.insurance_program_type, {});
      rb.query('plan_year', params.plan_year, {});
      rb.query('language', params.language, {});
      rb.query('explain', params.explain, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeFacilitySearchResponse>;
      })
    );
  }

  /**
   * Organization Affiliation Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making<br/>a HTTPS GET Call. This interface is invoked when the user has toggled to the facility radio option and clicked on search on the expanded search<br/>screen.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbePracticeSearchFuture$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  wahbePracticeSearchFuture(params: {

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF. <br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeFacilitySearchResponse> {

    return this.wahbePracticeSearchFuture$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeFacilitySearchResponse>) => r.body as WahbeFacilitySearchResponse)
    );
  }

  /**
   * Path part for operation wahbeOrgAffDetail
   */
  static readonly WahbeOrgAffDetailPath = '/wahbe/practices-future/{uid}';

  /**
   * Organization Affiliation Detail Request.
   *
   * Retrieve facility detail information by the unique ID (uid) supplied in the search view.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbeOrgAffDetail()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  wahbeOrgAffDetail$Response(params: {

    /**
     * The UID of the Organization Affiliation
     */
    uid: string;

    /**
     * Set to true to turn on debugging
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeFacilitySearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbeOrgAffDetailPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.query('explain', params.explain, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
      rb.header('x-user-id', params['x-user-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeFacilitySearchResponse>;
      })
    );
  }

  /**
   * Organization Affiliation Detail Request.
   *
   * Retrieve facility detail information by the unique ID (uid) supplied in the search view.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbeOrgAffDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  wahbeOrgAffDetail(params: {

    /**
     * The UID of the Organization Affiliation
     */
    uid: string;

    /**
     * Set to true to turn on debugging
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeFacilitySearchResponse> {

    return this.wahbeOrgAffDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeFacilitySearchResponse>) => r.body as WahbeFacilitySearchResponse)
    );
  }

  /**
   * Path part for operation wahbeDoctorSearch
   */
  static readonly WahbeDoctorSearchPath = '/wahbe/doctors';

  /**
   * Doctor Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making
   * a HTTPS GET call.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbeDoctorSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbeDoctorSearch$Response(params: {

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF.<br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Gender (drop down) on Search screen, only accepting values of 'male' and 'female'.
     */
    gender?: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * (Internal usage) Override the ES collection.
     */
    collection?: string;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeDoctorSearchResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbeDoctorSearchPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('insurance_program_type', params.insurance_program_type, {});
      rb.query('specialty_uid', params.specialty_uid, {});
      rb.query('location', params.location, {});
      rb.query('gender', params.gender, {});
      rb.query('sort', params.sort, {});
      rb.query('skip', params.skip, {});
      rb.query('limit', params.limit, {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('language', params.language, {});
      rb.query('plan_year', params.plan_year, {});
      rb.query('explain', params.explain, {});
      rb.query('collection', params.collection, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeDoctorSearchResponse>;
      })
    );
  }

  /**
   * Doctor Search.
   *
   * This interface would be used to fetch all the doctors from the QHP, Family Dental, and Medicaid Explore Your Options (EYO) screens by making
   * a HTTPS GET call.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbeDoctorSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbeDoctorSearch(params: {

    /**
     * Name (text field) on Search screen.
     */
    name?: string;

    /**
     * Type of insurance program, allow multiple values separated by comma (','). Allowed values are 'qhq','qdp' and 'wah'. <br/>For WAH EYO, both “qhp” and “wah” will be passed.For QHP EYO, “qhp” will be passed.For QDP EYO, “qpp” will be passed.
     */
    insurance_program_type?: string;

    /**
     * Specialty (dropdown) on Search screen. <br/>Static list of specialties stored in HPF (table name pending).
     */
    specialty_uid?: string;

    /**
     * (Required) Static mapping of geocodes to zipcodes stored in HPF.<br/>Mapping of ZIP codes (WA, OR, ID) to lat-long in HPF (table name pending).
     */
    location: string;

    /**
     * Gender (drop down) on Search screen, only accepting values of 'male' and 'female'.
     */
    gender?: string;

    /**
     * Allow only literal value 'distance-asc' or blank. If specified, the outcome will be arranged in ascending order based on the distance between the practice/provider and the geo point.
     */
    sort?: string;

    /**
     * Number of items to be skipped, has to be a numeric value, default to 0.
     */
    skip?: number;

    /**
     * Maximum number of items to be included in the result, has to be numeric value, default to 50.
     */
    limit?: number;

    /**
     * Unique id per user that is requesting data from convergent. <br/>No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * Code for the language selected on HPF. Refer to the attachment for the list of languages.<br/>Only one value would be sent in the request, and only the code would be sent to BD.
     */
    language?: string;

    /**
     * Plan year
     */
    plan_year?: number;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * (Internal usage) Override the ES collection.
     */
    collection?: string;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeDoctorSearchResponse> {

    return this.wahbeDoctorSearch$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeDoctorSearchResponse>) => r.body as WahbeDoctorSearchResponse)
    );
  }

  /**
   * Path part for operation wahbeProviderDetail
   */
  static readonly WahbeProviderDetailPath = '/wahbe/doctors/{uid}';

  /**
   * Doctor More Information.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `wahbeProviderDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbeProviderDetail$Response(params: {

    /**
     * Unique ID assigned to the doctor.
     */
    uid: string;

    /**
     * Specify the type of the UID, only support `npi` (case senstive) for now.
     */
    type?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<WahbeDoctorDetailResponse>> {

    const rb = new RequestBuilder(this.rootUrl, WashingtonHealthBenefitExchangeService.WahbeProviderDetailPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.query('type', params.type, {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
      rb.header('x-correlation-id', params['x-correlation-id'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WahbeDoctorDetailResponse>;
      })
    );
  }

  /**
   * Doctor More Information.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `wahbeProviderDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  wahbeProviderDetail(params: {

    /**
     * Unique ID assigned to the doctor.
     */
    uid: string;

    /**
     * Specify the type of the UID, only support `npi` (case senstive) for now.
     */
    type?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;

    /**
     * The ID to uniquely identify the request.
     */
    'x-correlation-id'?: string;
  },
  context?: HttpContext

): Observable<WahbeDoctorDetailResponse> {

    return this.wahbeProviderDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<WahbeDoctorDetailResponse>) => r.body as WahbeDoctorDetailResponse)
    );
  }

}
