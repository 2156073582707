import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultCardExpandDetailsComponent } from './search-result-card-expand-details.component';
// import { BahamutModule } from 'src/app/bahamut.module';
import {SearchResultCardExpandTab2Module} from "./search-result-card-expand-tab2/search-result-card-expand-tab2.module";
import {SearchResultCardExpandTab1Module} from "./search-result-card-expand-tab1/search-result-card-expand-tab1.module";
import {SearchResultCardExpandTab3Module} from "./search-result-card-expand-tab3/search-result-card-expand-tab3.module";
import {Button, Container} from "lo-bahamut";

@NgModule({
  declarations: [SearchResultCardExpandDetailsComponent],
  exports: [SearchResultCardExpandDetailsComponent],
    imports: [CommonModule,
        // BahamutModule,
        Container,
        SearchResultCardExpandTab2Module, SearchResultCardExpandTab1Module, SearchResultCardExpandTab3Module, Button],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultCardExpandDetailsModule {}
