/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { KEngineResponseContainerObject } from '../models/k-engine-response-container-object';
import { KEngineResponseContainerProviderResponseOfMasteredLocations } from '../models/k-engine-response-container-provider-response-of-mastered-locations';
import { KEngineResponseContainerProviderResponseOfPractitionerRoles } from '../models/k-engine-response-container-provider-response-of-practitioner-roles';
import { ProviderSearchRequest } from '../models/provider-search-request';

@Injectable({
  providedIn: 'root',
})
export class ProviderSearchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation sandboxProviderSearch
   */
  static readonly SandboxProviderSearchPath = '/sandbox/search';

  /**
   * Play ground to experiment with the request settings, should not be used for system integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sandboxProviderSearch()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  sandboxProviderSearch$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Full Generic Queryable Request including all the settings
     */
    body: {
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerObject>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.SandboxProviderSearchPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerObject>;
      })
    );
  }

  /**
   * Play ground to experiment with the request settings, should not be used for system integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sandboxProviderSearch$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  sandboxProviderSearch(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Full Generic Queryable Request including all the settings
     */
    body: {
}
  },
  context?: HttpContext

): Observable<KEngineResponseContainerObject> {

    return this.sandboxProviderSearch$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerObject>) => r.body as KEngineResponseContainerObject)
    );
  }

  /**
   * Path part for operation multiTenantProviderSearchV2
   */
  static readonly MultiTenantProviderSearchV2Path = '/v2/provider/search';

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderSearchV2()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderSearchV2Path, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>;
      })
    );
  }

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderSearchV2$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles> {

    return this.multiTenantProviderSearchV2$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>) => r.body as KEngineResponseContainerProviderResponseOfPractitionerRoles)
    );
  }

  /**
   * Path part for operation multiTenantProviderSearch
   */
  static readonly MultiTenantProviderSearchPath = '/practitioner-role/search';

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderSearch()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearch$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Basic provider Request.
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderSearchPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>;
      })
    );
  }

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderSearch$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearch(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Basic provider Request.
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles> {

    return this.multiTenantProviderSearch$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>) => r.body as KEngineResponseContainerProviderResponseOfPractitionerRoles)
    );
  }

  /**
   * Path part for operation multiTenantProviderDetail
   */
  static readonly MultiTenantProviderDetailPath = '/practitioner-role/{uid}';

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetail$Response(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderDetailPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetail(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfMasteredLocations> {

    return this.multiTenantProviderDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>) => r.body as KEngineResponseContainerProviderResponseOfMasteredLocations)
    );
  }

  /**
   * Path part for operation multiTenantProviderSearchV2CareFindr
   */
  static readonly MultiTenantProviderSearchV2CareFindrPath = '/v2/provider/search-carefindr';

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderSearchV2CareFindr()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2CareFindr$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderSearchV2CareFindrPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>;
      })
    );
  }

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderSearchV2CareFindr$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2CareFindr(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles> {

    return this.multiTenantProviderSearchV2CareFindr$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>) => r.body as KEngineResponseContainerProviderResponseOfPractitionerRoles)
    );
  }

  /**
   * Path part for operation multiTenantProviderDetailCareFindr
   */
  static readonly MultiTenantProviderDetailCareFindrPath = '/carefindr/practitioner-role/{uid}';

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderDetailCareFindr()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetailCareFindr$Response(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderDetailCareFindrPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderDetailCareFindr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetailCareFindr(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfMasteredLocations> {

    return this.multiTenantProviderDetailCareFindr$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>) => r.body as KEngineResponseContainerProviderResponseOfMasteredLocations)
    );
  }

  /**
   * Path part for operation multiTenantProviderSearchV2CareFindrDev
   */
  static readonly MultiTenantProviderSearchV2CareFindrDevPath = '/v2/provider/search-carefindr-dev';

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderSearchV2CareFindrDev()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2CareFindrDev$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderSearchV2CareFindrDevPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>;
      })
    );
  }

  /**
   * Multi-tenant provider search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderSearchV2CareFindrDev$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantProviderSearchV2CareFindrDev(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Provider Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: ProviderSearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles> {

    return this.multiTenantProviderSearchV2CareFindrDev$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfPractitionerRoles>) => r.body as KEngineResponseContainerProviderResponseOfPractitionerRoles)
    );
  }

  /**
   * Path part for operation multiTenantProviderDetailCareFindrDev
   */
  static readonly MultiTenantProviderDetailCareFindrDevPath = '/carefindr/practitioner-role-dev/{uid}';

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantProviderDetailCareFindrDev()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetailCareFindrDev$Response(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, ProviderSearchService.MultiTenantProviderDetailCareFindrDevPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant provider detail.
   *
   * This interface is invoked when the user clicks on the name of the provider on the provider results page, in order to fetch additional information<br/>such as certification, education, all the languages spoken, Board Certification by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantProviderDetailCareFindrDev$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantProviderDetailCareFindrDev(params: {

    /**
     * Unique ID assigned to the provider.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerProviderResponseOfMasteredLocations> {

    return this.multiTenantProviderDetailCareFindrDev$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerProviderResponseOfMasteredLocations>) => r.body as KEngineResponseContainerProviderResponseOfMasteredLocations)
    );
  }

}
