import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss']
})


export class ErrorHandlerComponent implements OnInit, OnDestroy {
  message: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.message = 'You are not authorized to view this, please contact support.'
  }
  destroyed$ = new Subject<void>();

  ngOnInit(): void {
    var x = this.route.snapshot.paramMap.getAll('msg');
    console.log(x);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  returnToStart() {
    this.router.navigate(['search-start']);
  }

}


