import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AggregateRootOfFacilityOrganizationAffiliation, AggregateRootOfPractitionerPractitionerRole } from 'src/app/_core/api/models';
import { SearchState } from 'src/app/_core/services/search-state.service';
import { SearchCardData, SearchResultCardAdapterService } from 'src/app/_core/services/searchResultCard/search-result-card-adapter.service';
import {CURRENT_TENNANT} from "../../app.component";

@Component({
  selector: 'app-print-search-results',
  templateUrl: './print-search-results.component.html',
  styleUrls: ['./print-search-results.component.scss']
})
export class PrintSearchResultsComponent implements OnInit, OnChanges {

  @Input() aggregateRoot!:
  | AggregateRootOfPractitionerPractitionerRole[]
  | AggregateRootOfFacilityOrganizationAffiliation[];

  @Input() type!: 'practitioner' | 'facility';

  @Input() printState!: SearchState;

  cardData: SearchCardData[] = [];
  parsedData: { key: string; value: any }[][] = [];
  searchSummary?: string;

  constructor(private searchCardAdapter: SearchResultCardAdapterService) {}

  ngOnInit(): void {
    this.prepData();
  }

  ngOnChanges() {
    this.prepData();
  }

  prepData() {
    this.cardData = [];
    if(this.type === 'practitioner') {
      if(this.aggregateRoot) {
        (this.aggregateRoot as AggregateRootOfPractitionerPractitionerRole[]).forEach((item) => {
          this.cardData.push(this.searchCardAdapter.adaptProvider(item))
        });
      } else {
        this.cardData = [];
      }
    }
    if(this.type === 'facility') {
      if(this.aggregateRoot) {
        (this.aggregateRoot as AggregateRootOfFacilityOrganizationAffiliation[]).forEach((item) => {
          this.cardData.push(this.searchCardAdapter.adaptFacility(item))
        });
      } else {
        this.cardData = [];
      }
    }
  }

  buildResultSummary(): string{
    let query = this.printState.Query
    let extra = this.printState.Extra

    let searchType = (this.type === 'practitioner' ? ' provider ' : ' facility ')
    let count = this.aggregateRoot ? this.aggregateRoot.length.toString() : '0'
    let name = (query.name ? '[' + query.name + ']' : '')
    let location = (extra.locationText? '[' + extra.locationText + ' ' + query.location?.distance + ' mile(s)' + ']' : '')
    let specialty = ''
    if('specialty' in query && query.specialty !== undefined) {
      specialty = '[' + query.specialty + ']'
    }
    let language = (query.language ? '[' + query.language + ']' : '')
    let gender = ''
    if('gender' in query && query.gender !== undefined) {
      gender = '[' + query.gender + ']'
    }

    return count + searchType + "results found for " + name + location + specialty + language + gender;
  }

  protected readonly CURRENT_TENNANT = CURRENT_TENNANT;
}
