import { Injectable } from '@angular/core';
import { forEach, join, padStart } from 'lodash-es';
import { OrganizationAffiliationsGroupedByLocation } from '../../api/models/organization-affiliations-grouped-by-location';
import { PractitionerRolesGroupedByLocation } from '../../api/models/practitioner-roles-grouped-by-location';
import {Communication, Specialty, Telecom} from '../../api/models';
import { SearchStateService } from '../search-state.service';
import { GoogleApiKey } from '../google-api.service';

export interface processedTelecom {
  indicators: {
    phonePresent: boolean;
    faxPresent: boolean;
    urlPresent: boolean;
  };
  phone?: string[];
  fax?: string[];
  url?: string[];
}
@Injectable({
  providedIn: 'root',
})
export class SearchResultCardExpandTab2Service {
  constructor(private searchStateService: SearchStateService) {}

  processTelecom(Telecoms: Telecom[]): processedTelecom {
    const addValueToField = (
      res: processedTelecom,
      field: 'phone' | 'fax' | 'url',
      value: string
    ) => {
      if (!res[field]) {
        res[field] = [];
        res.indicators[
          `${field}Present` as keyof processedTelecom['indicators']
        ] = true;
      }
      (res[field] as string[]).push(value);
    };

    let res: processedTelecom = {
      indicators: {
        phonePresent: false,
        faxPresent: false,
        urlPresent: false,
      },
    };

    Telecoms.forEach((t) => {
      if (['phone', 'fax', 'url'].includes(t.system!)) {
        addValueToField(res, t.system! as 'phone' | 'fax' | 'url', t.value!);
      }
    });

    return res;
  }

  transformTime(time: string | null | undefined): string | null {
    if (time === null || time === undefined) {
      return null;
    }

    const [hours, minutes, seconds] = time
      .split(':')
      .map((val) => parseInt(val));

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      return null;
    }

    const hours12 = hours % 12 || 12;
    const amPm = hours < 12 ? 'AM' : 'PM';

    const formattedTime =
      join(
        [
          padStart(hours12.toString(), 2, '0'),
          padStart(minutes.toString(), 2, '0'),
        ],
        ':'
      ) +
      ' ' +
      amPm;

    return formattedTime;
  }

  getFormattedAvailableTime(
    data:
      | OrganizationAffiliationsGroupedByLocation
      | PractitionerRolesGroupedByLocation
  ) {
    type AvailableTimeResult = {
      DayValue: string;
      TimeValues: string;
    } | null;

    let res: AvailableTimeResult[] | null = null;

    const currentLocation = data.location;
    if (!currentLocation) {
      return res;
    }

    const dayMapping = {
      MON: 'Monday',
      TUE: 'Tuesday',
      WED: 'Wednesday',
      THU: 'Thursday',
      FRI: 'Friday',
      SAT: 'Saturday',
      SUN: 'Sunday',
    } as const;

    const days = Object.keys(dayMapping) as Array<keyof typeof dayMapping>;

    // Map days to AvailableTimeResult objects
    res = days.map((day) => {
      const longFormDay = dayMapping[day];
      const dataForDay = currentLocation.availableTime?.find(
        (item) => item.daysOfWeek === day
      );
      if (dataForDay?.allDay == true) {
        return {
          DayValue: longFormDay,
          TimeValues: `12:00 AM - 12:00 AM`,
        } as AvailableTimeResult;
      }else if (dataForDay?.allDay == false && (dataForDay?.availableStartTime || dataForDay?.availableEndTime) ) {
        return {
          DayValue: longFormDay,
          TimeValues: `${this.transformTime(
            dataForDay.availableStartTime
          )} - ${this.transformTime(dataForDay.availableEndTime)}`,
        } as AvailableTimeResult;
      } else {
        return {
          DayValue: longFormDay,
          TimeValues: `--:-- - --:--`,
        } as AvailableTimeResult;
      }
    });

    return res;
  }

  getAllInsuranceCarriers(
    data:
      | OrganizationAffiliationsGroupedByLocation
      | PractitionerRolesGroupedByLocation
  ): string[] {
    const res = new Set<string>();
    const currentNetwork = data.network;
    forEach(currentNetwork, (i) => {
      if (i && i.insurance) {
        const currentInsurance = i.insurance[0];
        if (currentInsurance.carrier && currentInsurance.carrier.name && !(i.meta?.['suppress'])) {
          res.add(currentInsurance.carrier.name);
        }
      }
    });
    return Array.from(res);
  }

  getUniqueSpecialties(data: Specialty[]) {
    const res = new Set<string>();
    data.map((specialty) => {
      if (specialty.display) res.add(specialty.display);
    });

    return Array.from(res);
  }

  getMapOptions(
    currentRoot:
      | PractitionerRolesGroupedByLocation
      | OrganizationAffiliationsGroupedByLocation
  ): google.maps.MapOptions {
    return {
      center: this.getCurrentPosition(currentRoot),
      zoom: 15,
      draggable: false,
    };
  }

  getCurrentPosition(
    currentRoot:
      | PractitionerRolesGroupedByLocation
      | OrganizationAffiliationsGroupedByLocation
  ) {
    const currentGeoPosition = currentRoot.location!.position![0];
    return { lat: currentGeoPosition.lat!, lng: currentGeoPosition.lon! };
  }

  getStaticMapSrc(
    currentRoot:
      | PractitionerRolesGroupedByLocation
      | OrganizationAffiliationsGroupedByLocation
  ) {
    const currentGeoPosition = currentRoot.location!.position![0];
    const staticMapBaseUri = `https://maps.googleapis.com/maps/api/staticmap?`;
    const center = `${currentGeoPosition.lat},${currentGeoPosition.lon}`;
    const size = `350x350`;
    const markers = `label:A|scale:2|${center}`;

    return `${staticMapBaseUri}?center=${center}&zoom=14&size=${size}&markers=${markers}&key=${GoogleApiKey}`;
  }

  goToMapDirections(
    currentRoot:
      | PractitionerRolesGroupedByLocation
      | OrganizationAffiliationsGroupedByLocation
  ) {
    const currentGeoPosition = currentRoot.location!.position![0];
    const origin = `${
      encodeURIComponent(
        this.searchStateService.getSearchCriteria().Extra.locationText!
      ) ?? ''
    }`;
    const destination =
      encodeURIComponent(currentRoot.location?.address![0].text!) ??
      `${currentGeoPosition.lat},${currentGeoPosition.lon}`;

    console.log(destination);
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
    window.open(url, '_blank');
  }

  getUniqueCommunication(
    data: Communication[]
  ): string[]{
    const res = new Set<string>();
    const currentLang = data;
    forEach(currentLang, (i) => {
      if(i.display) res.add(i.display)
    });
    return Array.from(res);
  }
}
