import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  AggregateRootOfPractitionerPractitionerRole,
  AggregateRootOfFacilityOrganizationAffiliation,
  FacilityResponseOfMasteredLocations,
  ProviderResponseOfMasteredLocations,
} from 'src/app/_core/api/models';
import { SearchService } from 'src/app/_core/services/search.service';
import { SearchCardData, SearchResultCardAdapterService } from "../../_core/services/searchResultCard/search-result-card-adapter.service";
import { LoadingSpinnerService } from 'src/app/_core/services/loading-spinner.service';
import { PrintPdfService } from 'src/app/_core/services/print-pdf.service';

@Component({
  selector: 'app-search-result-card',
  templateUrl: './search-result-card.component.html',
  styleUrls: ['./search-result-card.component.scss'],
})
export class SearchResultCardComponent implements OnInit, OnDestroy {
  isExpanded: boolean = false;
  loadingPrint: boolean = false;

  iconMapping: { [key: string]: string } = {
    Phone: 'call',
    Fax: 'fax',
    Address: 'location_on',
    Gender: 'person',
    Website: 'language',
  };
  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private searchService: SearchService,
    private searchCardAdapter: SearchResultCardAdapterService,
    private printService: PrintPdfService
  ) {}
  @Input() aggregateRoot!:
    | AggregateRootOfPractitionerPractitionerRole
    | AggregateRootOfFacilityOrganizationAffiliation;

  @Input() type!: 'practitioner' | 'facility';

  detailCallSubscription?: Subscription;
  cardData?: SearchCardData = undefined;
  detailsData!:
    | FacilityResponseOfMasteredLocations
    | ProviderResponseOfMasteredLocations;

  ngOnInit(): void {
    if (this.type === 'practitioner') {
      this.cardData = this.searchCardAdapter.adaptProvider(
        this.aggregateRoot as AggregateRootOfPractitionerPractitionerRole
      );
    } else if (this.type === 'facility') {
      this.cardData = this.searchCardAdapter.adaptFacility(
        this.aggregateRoot as AggregateRootOfFacilityOrganizationAffiliation
      );
    }
    this.getCardDataArray();
  }

  ngOnDestroy(): void {
    if (this.detailCallSubscription) {
      this.detailCallSubscription.unsubscribe();
    }
  }

  handleShowMoreDetailsClick() {
    if (!this.isExpanded) {
      this.loadingSpinnerService.showLoader();
      if (this.type === 'practitioner') {
        this.detailCallSubscription = this.searchService
          .getProviderDetails(this.cardData?.extras.RootStableId!)
          .subscribe(
            (data: ProviderResponseOfMasteredLocations | undefined) => {
              if (data != undefined) {
                this.isExpanded = !this.isExpanded; //this line is here to only expand when after the result has come back
                this.detailsData = data;
              }
              this.loadingSpinnerService.hideLoader();
            }
          );
      } else if (this.type === 'facility') {
        this.detailCallSubscription = this.searchService
          .getFacilityDetails(this.cardData?.extras.RootStableId!)
          .subscribe(
            (data: FacilityResponseOfMasteredLocations | undefined) => {
              if (data != undefined) {
                this.isExpanded = !this.isExpanded; //this line is here to only expand when after the result has come back
                this.detailsData = data;
              }
              this.loadingSpinnerService.hideLoader();
            }
          );
      }
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  handlePrintProviderClick() {
    this.loadingPrint = true;
    this.printService.getPrintPdfForSingleProvider(this.detailsData).subscribe(pdfBlob => {
      const blobUrl = URL.createObjectURL(pdfBlob)
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = blobUrl
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow?.print();
      }
      this.loadingPrint = false;
    })
  }

  handlePrintFacilityClick(){
    this.loadingPrint = true;
    this.printService.getPrintPdfForSingleFacility(this.detailsData).subscribe(pdfBlob => {
      const blobUrl = URL.createObjectURL(pdfBlob)
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = blobUrl
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow?.print();
      }
      this.loadingPrint = false;
    })
  }

  getCardDataArray(): { key: string; value: any }[] {
    if (!this.cardData?.list) {
      return [];
    }

    return Object.entries(this.cardData?.list).map(([key, value]) => ({
      key,
      value: this.formatValue(key, value) || undefined,
    }));
  }
  getIconName(key: string): string {
    return this.iconMapping[key] || '';
  }

  private formatValue(key: string, value: any): string {
    if (key === 'Phone' || key === 'Fax') {
      // Check if the value is defined and is a string
      if (value && typeof value === 'string') {
        // Check if the value matches the regex
        const regex = /\(?(\d{3})?\)?[-.\s]?(\d{3})?[-.\s]?(\d{4})?/;
        const match = value.match(regex);

        if (match) {
          return `(${match[1] || 'XXX'}) ${match[2] || 'XXX'}-${match[3] || 'XXXX'}`;
        }
      }
    }

    return value;
  }

  truncateDistance(dist: string | undefined): string {
    if(dist) return parseFloat(dist).toFixed(2);
    return 'N/A'
  }

  capitalizeEachWord(text: string): string {
    if (!text) return ''; // Handle undefined or null cases
    return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
  getCardIdentifier(){

  }
}
