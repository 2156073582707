/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilitySearchRequest } from '../models/facility-search-request';
import { KEngineResponseContainerFacilityResponseOfMasteredLocations } from '../models/k-engine-response-container-facility-response-of-mastered-locations';
import { KEngineResponseContainerFacilityResponseOfOrgAffiliations } from '../models/k-engine-response-container-facility-response-of-org-affiliations';

@Injectable({
  providedIn: 'root',
})
export class FacilitySearchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation multiTenantFacilitySearchV2
   */
  static readonly MultiTenantFacilitySearchV2Path = '/v2/facility/search';

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilitySearchV2()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilitySearchV2Path, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>;
      })
    );
  }

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilitySearchV2$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations> {

    return this.multiTenantFacilitySearchV2$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>) => r.body as KEngineResponseContainerFacilityResponseOfOrgAffiliations)
    );
  }

  /**
   * Path part for operation multiTenantFacilitySearch
   */
  static readonly MultiTenantFacilitySearchPath = '/facility/search';

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilitySearch()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearch$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Basic facility Request.
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilitySearchPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>;
      })
    );
  }

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilitySearch$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearch(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Basic facility Request.
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations> {

    return this.multiTenantFacilitySearch$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>) => r.body as KEngineResponseContainerFacilityResponseOfOrgAffiliations)
    );
  }

  /**
   * Path part for operation multiTenantFacilityDetail
   */
  static readonly MultiTenantFacilityDetailPath = '/facility/{uid}';

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilityDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetail$Response(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilityDetailPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilityDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetail(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfMasteredLocations> {

    return this.multiTenantFacilityDetail$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>) => r.body as KEngineResponseContainerFacilityResponseOfMasteredLocations)
    );
  }

  /**
   * Path part for operation multiTenantFacilitySearchV2CareFindr
   */
  static readonly MultiTenantFacilitySearchV2CareFindrPath = '/v2/facility/search-carefindr';

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilitySearchV2CareFindr()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2CareFindr$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilitySearchV2CareFindrPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>;
      })
    );
  }

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilitySearchV2CareFindr$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2CareFindr(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations> {

    return this.multiTenantFacilitySearchV2CareFindr$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>) => r.body as KEngineResponseContainerFacilityResponseOfOrgAffiliations)
    );
  }

  /**
   * Path part for operation multiTenantFacilityDetailCareFindr
   */
  static readonly MultiTenantFacilityDetailCareFindrPath = '/carefindr/facility/{uid}';

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilityDetailCareFindr()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetailCareFindr$Response(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilityDetailCareFindrPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilityDetailCareFindr$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetailCareFindr(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfMasteredLocations> {

    return this.multiTenantFacilityDetailCareFindr$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>) => r.body as KEngineResponseContainerFacilityResponseOfMasteredLocations)
    );
  }

  /**
   * Path part for operation multiTenantFacilitySearchV2CareFindr_1
   */
  static readonly MultiTenantFacilitySearchV2CareFindr_1Path = '/v2/facility/search-carefindr-dev';

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilitySearchV2CareFindr_1()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2CareFindr_1$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilitySearchV2CareFindr_1Path, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>;
      })
    );
  }

  /**
   * Multi-tenant facility search.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilitySearchV2CareFindr_1$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  multiTenantFacilitySearchV2CareFindr_1(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Two Stroke Facility Request. See design: https://leaporbit.atlassian.net/wiki/spaces/CONVERGENT/pages/53739533/Two-stroke+Design
     */
    body: FacilitySearchRequest
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations> {

    return this.multiTenantFacilitySearchV2CareFindr_1$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfOrgAffiliations>) => r.body as KEngineResponseContainerFacilityResponseOfOrgAffiliations)
    );
  }

  /**
   * Path part for operation multiTenantFacilityDetailCareFindrDev
   */
  static readonly MultiTenantFacilityDetailCareFindrDevPath = '/carefindr/facility-dev/{uid}';

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `multiTenantFacilityDetailCareFindrDev()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetailCareFindrDev$Response(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>> {

    const rb = new RequestBuilder(this.rootUrl, FacilitySearchService.MultiTenantFacilityDetailCareFindrDevPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.header('x-user-id', params['x-user-id'], {});
      rb.query('explain', params.explain, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>;
      })
    );
  }

  /**
   * Multi-tenant facility detail.
   *
   * This interface is invoked when the user clicks on the name of the facility on the facility results page, in order to fetch additional information<br/>such as facility type, all the languages spoken, etc,by making a HTTPS GET Call. This endpoint is for CareFindr usage only.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `multiTenantFacilityDetailCareFindrDev$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  multiTenantFacilityDetailCareFindrDev(params: {

    /**
     * Unique ID assigned to the facility.
     */
    uid: string;

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Unique id per user that is requesting data from convergent. <br/> No cap on the number of hits to APIs
     */
    'x-user-id'?: string;

    /**
     * (Internal usage) Set to true to turn on debugging.
     */
    explain?: boolean;
  },
  context?: HttpContext

): Observable<KEngineResponseContainerFacilityResponseOfMasteredLocations> {

    return this.multiTenantFacilityDetailCareFindrDev$Response(params,context).pipe(
      map((r: StrictHttpResponse<KEngineResponseContainerFacilityResponseOfMasteredLocations>) => r.body as KEngineResponseContainerFacilityResponseOfMasteredLocations)
    );
  }

}
