import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Input, Output,
  ViewChild,
} from '@angular/core';
import { SearchStateService } from 'src/app/_core/services/search-state.service';

@Component({
  selector: 'app-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  styleUrls: ['./location-autocomplete.component.scss'],
})
export class LocationAutocompleteComponent implements AfterViewInit {
  @Input() placeHolder = '';
  @ViewChild('inputField') inputField!: ElementRef;
  @Output() locationText: EventEmitter<any> = new EventEmitter<any>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  constructor(private searchStateService: SearchStateService) {}

  ngAfterViewInit() {
    // Load google maps script after view init
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement
    );

    if (this.autocomplete) {
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete?.getPlace();

        if (place && place.geometry && place.geometry.location) {
          // Handle the selected place, which is a GeoJSON feature
          this.searchStateService.updateSearchCriteria({
            Query: {
              ...this.searchStateService.getSearchCriteria().Query,
              location: {
                latitude: place.geometry.location.lat() as unknown as string,
                longitude: place.geometry.location.lng() as unknown as string,
                distance:
                  this.searchStateService.getSearchCriteria().Query.location
                    ?.distance ?? '25',
              },
            },
            Extra: { locationText: place.formatted_address },
          });
        }
        return this.locationText.emit(place?.formatted_address)
      });
    }
  }
}
