import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultCardExpandTab3Component } from './search-result-card-expand-tab3.component';
// import {BahamutModule} from "../../../../bahamut.module";
import {CustomCarouselModule} from "../../../../_shared/custom-carousel/custom-carousel.module";
import { Container } from "lo-bahamut";


@NgModule({
  declarations: [SearchResultCardExpandTab3Component],
  exports: [SearchResultCardExpandTab3Component],
  imports: [CommonModule,
    // BahamutModule,
    Container,
    CustomCarouselModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultCardExpandTab3Module { }
