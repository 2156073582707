/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityResponseOfMasteredLocations } from '../models/facility-response-of-mastered-locations';
import { FileStreamResult } from '../models/file-stream-result';
import { ProviderResponseOfMasteredLocations } from '../models/provider-response-of-mastered-locations';

@Injectable({
  providedIn: 'root',
})
export class PrintService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSingleProviderPrintPdf
   */
  static readonly GetSingleProviderPrintPdfPath = '/print/carefindr/print-single-provider';

  /**
   * Print Search Results.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleProviderPrintPdf()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  getSingleProviderPrintPdf$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Practitioner data to be printed
     */
    body: ProviderResponseOfMasteredLocations
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FileStreamResult>> {

    const rb = new RequestBuilder(this.rootUrl, PrintService.GetSingleProviderPrintPdfPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamResult>;
      })
    );
  }

  /**
   * Print Search Results.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleProviderPrintPdf$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  getSingleProviderPrintPdf(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Practitioner data to be printed
     */
    body: ProviderResponseOfMasteredLocations
  },
  context?: HttpContext

): Observable<FileStreamResult> {

    return this.getSingleProviderPrintPdf$Response(params,context).pipe(
      map((r: StrictHttpResponse<FileStreamResult>) => r.body as FileStreamResult)
    );
  }

  /**
   * Path part for operation getSingleFacilityPrintPdf
   */
  static readonly GetSingleFacilityPrintPdfPath = '/print/carefindr/print-single-facility';

  /**
   * Print Search Results.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSingleFacilityPrintPdf()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  getSingleFacilityPrintPdf$Response(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Facility data to be printed
     */
    body: FacilityResponseOfMasteredLocations
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FileStreamResult>> {

    const rb = new RequestBuilder(this.rootUrl, PrintService.GetSingleFacilityPrintPdfPath, 'post');
    if (params) {
      rb.header('x-tenant-id', params['x-tenant-id'], {});
      rb.header('x-persona', params['x-persona'], {});
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/pdf',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStreamResult>;
      })
    );
  }

  /**
   * Print Search Results.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSingleFacilityPrintPdf$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  getSingleFacilityPrintPdf(params: {

    /**
     * The tenant code
     */
    'x-tenant-id': string;

    /**
     * The audience of the return data
     */
    'x-persona'?: string;

    /**
     * Facility data to be printed
     */
    body: FacilityResponseOfMasteredLocations
  },
  context?: HttpContext

): Observable<FileStreamResult> {

    return this.getSingleFacilityPrintPdf$Response(params,context).pipe(
      map((r: StrictHttpResponse<FileStreamResult>) => r.body as FileStreamResult)
    );
  }

}
