import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BahamutModule } from '../bahamut.module';
import { SliderComponent } from './slider/slider.component';
import { LocationAutocompleteComponent } from './location-autocomplete/location-autocomplete.component';
import {FormsModule} from "@angular/forms";
import {Dropdown} from "lo-bahamut";
@NgModule({
  declarations: [SliderComponent, LocationAutocompleteComponent],
  imports: [CommonModule,
    // BahamutModule,
    FormsModule, Dropdown],
  exports: [SliderComponent, LocationAutocompleteComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
