<div class="flex provider-facility-information-block">
  <div col="3" class="provider-facility-information-col flex flex-column">
    <div class="info-heading">Specialties</div>
    <div class="info-content">
      <ul *ngIf="data && data.list">
        <li *ngFor="let specialty of data.list.Specialties">{{specialty}}</li>
      </ul>
    </div>
  </div>
  <div col="3" class="provider-facility-information-col flex flex-column">
    <div class="info-heading">Languages</div>
    <div class="info-content">
      <ul *ngIf="data && data.list">
        <li *ngFor="let language of data.list.Languages">{{language}}</li>
      </ul>
    </div>
  </div>
  <div col="3" *ngIf="dataType === 'practitioner'" class="provider-facility-information-col flex flex-column">
    <div class="info-heading">Additional Training</div>
    <div class="info-content">
      <ul *ngIf="data && data.list">
        <li *ngFor="let qualification of data.list.Qualifications">{{qualification}}</li>
      </ul>
    </div>
  </div>
<!--  <div col="3" class="provider-facility-information-col flex flex-column">-->
<!--    <div class="info-heading">Licenses</div>-->
<!--    <div class="info-content">-->
<!--      <ul *ngIf="data && data.list">-->
<!--        <li *ngFor="let identifier of data.list.Identifiers">{{identifier}}</li>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
  <div col="3" class="provider-facility-information-col flex flex-column">
    <div class="info-heading">Groups</div>
    <div class="info-content">
      <ul *ngIf="data && data.list">
        <li *ngFor="let group of data.list.Groups">{{group}}</li>
      </ul>
    </div>
  </div>
</div>
