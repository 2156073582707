<lo-container nogm maincontent search-start *ngIf="!showComingSoon; else elseBlock" class="center">
  <lo-container
    nogm
    class="flex-column search-start-block"
  >
    <p class="start-description">
      First, we need to know a few things before you can search. Please provide the required information. Anything that has a red asterisk (<span class="asterisk-red">*</span>) is required
    </p>
    <div class="flex flex-column search-start-content">
      <div class="form-row" *ngIf="isPlanDropDownEnabled">
        <lo-container nogm col="12" class="flex align-baseline select-plan-block">
          <lo-dropdown *ngIf="planOptions.length > 0"
                       nogm
                       id="plan-dropdown"
                       variant="basic"
                       placeholder="Select Your Plan"
                       label="Select Your Plan"
                       [options]="planOptions"
                       required="false"
                       (onChange)="handlePlanDropdown($event)"
                       title=""
                       #planDropdown
          >
          </lo-dropdown>
        </lo-container>
      </div>
      <div class="form-row">
        <lo-container nogm col="6" class="flex-column location-input space-between">
          <label data-required="true" class="location-input-header" for="location-input"
          >Choose Your Search Location</label
          >
          <app-location-autocomplete class="flex" (locationText)="locationFocus()"
                                     [placeHolder]="searchState.Extra.locationText ?? ''"
                                     #locationAutocomplete
          />
        </lo-container>
        <lo-container nogm col="6" class="align-baseline">
          <div class="range flex space-between flex-column">
            <label class="slider-label" for="miles-range">Within Distance:</label>
            <app-slider
              [minValue]="5"
              [maxValue]="100"
              [tickInterval]="5"
              [useTicks]="true"
              [currentValue]="searchState.Query.location?.distance ?? defaultSlideValue"
              (valueChange)="handleSliderValueChange($event)"
              #sliderComponent
            />
          </div>
        </lo-container>
      </div>
      <p data-required="true">
        Next, please provide a Provider / Facility Name OR search for a specialty. At least one is required to continue
      </p>
      <div class="form-row or-text-placement common-margin-bottom">
        <lo-container nogm col="5" class="flex align-baseline">
          <lo-input
            nogm
            id="name-input"
            variant="text"
            label="Provider / Facility Name"
            placeholder="Provider / Facility Name"
            [data]="searchState.Query.name ?? ''"
            maxlength="100"
            minlength="2"
            (onChange)="handleNameInput($event)"
            title=""
            #nameInput
          >
          </lo-input>
        </lo-container>
        <span class="or-text" data-required="true">or</span>
        <lo-container nogm col="5" class="align-baseline align-self-end"
                      *ngIf="specialtyOptions.length > 0">
          <lo-dropdown
            nogm
            id="specialty-dropdown"
            variant="tree-multiselect"
            placeholder="{{isSpecialityHeaderNeedToChange ? 'Provider Type / Specialty' : 'Specialty'}}"
            label="{{isSpecialityHeaderNeedToChange ? 'Provider Type / Specialty' : 'Specialty'}}"
            errormessage="Invalid selection"
            [levels]="specialtyOptions"
            [data]="[]"
            (onChange)="handleSpecialtyDropdown($event)"
            [role]="'combobox'"
            [ariaLabel]="'Specialty multiselect'"
            #specialtyDropdown
          >
          </lo-dropdown>
        </lo-container>
      </div>
    </div>
    <div class="submit-button-block flex space-between align-items">
    <lo-button
      nogm
      id="clear-button"
      variant="cancel"
      label="Clear"
      tooltip="Clear form"
      tooltipDirection="left"
      (onClick)="clearSearchInputs()"
    ></lo-button>
    <lo-button
      nogm
      id="submit-button"
      variant="action"
      icon="search-action"
      label="Search"
      class="btn-bg-blue"
      (onClick)="handleSearchClick()"
      type="button"
      [disabled]="!this.isSearchButtonEnabled()"
    ></lo-button>
  </div>
  </lo-container>
</lo-container>
<ng-template #elseBlock>
  <app-coming-soon />
</ng-template>
