import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CareFindrAzureApiService } from '../api/services';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private featureFlags = new Map<string, BehaviorSubject<boolean>>();

  constructor(private azureApiService: CareFindrAzureApiService) {}

  // Initialize feature flags
  initFeatureFlags(): void {
    this.loadFeatureFlag('showComingSoon');
    // Add/load other feature flags as needed
  }

  private loadFeatureFlag(featureFlagName: string): void {
    const flagSubject = new BehaviorSubject<boolean>(false); // Default value
    this.featureFlags.set(featureFlagName, flagSubject);

    this.azureApiService
      .getAzureFeatureFlagAsync({ featureFlagName })
      .subscribe({
        next: (resp) => flagSubject.next(resp),
        error: (error) => {
          console.error(
            `Error loading feature flag ${featureFlagName}:`,
            error
          );
          flagSubject.next(false); // Set default value in case of error
        },
      });
  }

  getFeatureFlag(featureFlagName: string): Observable<boolean> {
    if (!this.featureFlags.has(featureFlagName)) {
      this.loadFeatureFlag(featureFlagName); // Load the flag if it's not already being tracked
    }
    return this.featureFlags.get(featureFlagName)!.asObservable();
  }
}
