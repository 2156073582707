import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, map, catchError, BehaviorSubject, of, Subject, takeUntil } from 'rxjs';
import {
  ProviderSearchRequest,
  FacilitySearchRequest,
  KEngineResponseContainerProviderResponseOfMasteredLocations,
  ProviderResponseOfMasteredLocations,
  FacilityResponseOfMasteredLocations,
  KEngineResponseContainerFacilityResponseOfMasteredLocations,
  ProviderResponseOfPractitionerRoles,
  FacilityResponseOfOrgAffiliations,
  BasicProviderSearchQuery,
  BasicOrganizationSearchQuery,
  KEngineResponseContainerFacilityResponseOfOrgAffiliations,
  KEngineResponseContainerProviderResponseOfPractitionerRoles,
} from '../api/models';
import { ProviderSearchService, FacilitySearchService } from '../api/services';
import { CURRENT_TENNANT } from 'src/app/app.component';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements OnDestroy{
  constructor(
    private providerSearchService: ProviderSearchService,
    private facilitySearchService: FacilitySearchService
  ) {}

  private _providerSearchResults =
    new BehaviorSubject<ProviderResponseOfPractitionerRoles>({});
  private _facilitySearchResults =
    new BehaviorSubject<FacilityResponseOfOrgAffiliations>({});
  private _providerPrintResults =
    new BehaviorSubject<ProviderResponseOfPractitionerRoles>({});
  private _facilityPrintResults =
    new BehaviorSubject<FacilityResponseOfOrgAffiliations>({});

  private cancelProviderRequest$ = new Subject<void>();
  private cancelFacilityRequest$ = new Subject<void>();

  providerSearchResults$: Observable<ProviderResponseOfPractitionerRoles> =
    this._providerSearchResults.asObservable();
  facilitySearchResults$: Observable<FacilityResponseOfOrgAffiliations> =
    this._facilitySearchResults.asObservable();
  providerPrintResults$: Observable<ProviderResponseOfPractitionerRoles> =
    this._providerPrintResults.asObservable();
  facilityPrintResults$: Observable<FacilityResponseOfOrgAffiliations> =
    this._facilityPrintResults.asObservable();

  getProviderResults(
    queryParams: BasicProviderSearchQuery,
    print: boolean = false
  ): Observable<ProviderResponseOfPractitionerRoles> {

    const requestBody: ProviderSearchRequest = {
      queryType: 'mtProviderSearchV2',
      query: queryParams,
    };

    if(print) {
      this._providerPrintResults.next({});
      this.cancelProviderRequest$.next();
    }

    return this.providerSearchService
      .multiTenantProviderSearchV2CareFindrDev({
        'x-tenant-id': CURRENT_TENNANT,
        'x-persona': 'user',
        body: requestBody,
      })
      .pipe(
        this.takeProviderHelper(print),
        map((response) => {
          const dataResponse = response.data;
          if (dataResponse?.total && dataResponse.providers) {
            print ? this._providerPrintResults.next(dataResponse) :
                  this._providerSearchResults.next(dataResponse)
            return dataResponse;
          }
          console.log(
            dataResponse ? 'No Results Found.' : 'Response Undefined'
          );
          const emptyResponse: ProviderResponseOfPractitionerRoles = {providers: [], meta: {}, total: 0};
          print ? this._providerPrintResults.next(emptyResponse) :
                  this._providerSearchResults.next(emptyResponse)
          return emptyResponse;
        }),
        catchError((e: HttpErrorResponse) => {
          console.log(e);
          const emptyResponse: ProviderResponseOfPractitionerRoles = {providers: [], meta: {}, total: 0};
          print ? this._providerPrintResults.next(emptyResponse) :
                  this._providerSearchResults.next(emptyResponse)
          return of(emptyResponse);
        })
      );
  }

  getFacilityResults(
    queryParams: BasicOrganizationSearchQuery,
    print: boolean = false
  ): Observable<FacilityResponseOfOrgAffiliations> {
    const requestBody: FacilitySearchRequest = {
      queryType: 'mtOrganizationSearchV2',
      query: queryParams,
    };
    
    if(print) {
      this._facilityPrintResults.next({});
      this.cancelFacilityRequest$.next();
    }

    return this.facilitySearchService
      .multiTenantFacilitySearchV2CareFindr_1({
        'x-tenant-id': CURRENT_TENNANT,
        'x-persona': 'user',
        body: requestBody,
      })
      .pipe(
        this.takeFacilityHelper(print),
        map((response) => {
          const dataResponse = response.data;
          if (dataResponse?.total && dataResponse.facilities) {
            print ? this._facilityPrintResults.next(dataResponse) : 
                    this._facilitySearchResults.next(dataResponse);
            return dataResponse;
          }
          console.log(
            dataResponse ? 'No Results Found.' : 'Response Undefined'
          );
          const emptyResponse: FacilityResponseOfOrgAffiliations = {facilities: [], meta: {}, total: 0};
          print ? this._facilityPrintResults.next(emptyResponse) : 
                  this._facilitySearchResults.next(emptyResponse);
          return emptyResponse;
        }),
        catchError((e: HttpErrorResponse) => {
          console.log(e);
          const emptyResponse: FacilityResponseOfOrgAffiliations = {facilities: [], meta: {}, total: 0};
          print ? this._facilityPrintResults.next(emptyResponse) : 
                  this._facilitySearchResults.next(emptyResponse);
          return of(emptyResponse);
        })
      );
  }

  getProviderDetails(
    stableId: string
  ): Observable<ProviderResponseOfMasteredLocations | undefined> {
    return this.providerSearchService
      .multiTenantProviderDetailCareFindrDev({
        'x-tenant-id': CURRENT_TENNANT,
        'x-persona': 'user',
        uid: stableId,
      })
      .pipe(
        map(
          (
            response: KEngineResponseContainerProviderResponseOfMasteredLocations
          ) => {
            return response.data;
          }
        ),
        catchError((e: HttpErrorResponse) => {
          console.log(e);
          return of(undefined);
        })
      );
  }

  getFacilityDetails(
    stableId: string
  ): Observable<FacilityResponseOfMasteredLocations | undefined> {
    return this.facilitySearchService
      .multiTenantFacilityDetailCareFindrDev({
        'x-tenant-id': CURRENT_TENNANT,
        'x-persona': 'user',
        uid: stableId,
      })
      .pipe(
        map(
          (
            response: KEngineResponseContainerFacilityResponseOfMasteredLocations
          ) => {
            return response.data;
          }
        ),
        catchError((e: HttpErrorResponse) => {
          console.log(e);
          return of(undefined);
        })
      );
  }

  // Method to get current provider results
  getCurrentProviderResults(): ProviderResponseOfPractitionerRoles {
    return this._providerSearchResults.getValue();
  }

  // Method to get current facility results
  getCurrentFacilityResults(): FacilityResponseOfOrgAffiliations {
    return this._facilitySearchResults.getValue();
  }

  takeProviderHelper(print: boolean) {
    return (source: Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles>):               Observable<KEngineResponseContainerProviderResponseOfPractitionerRoles> => {
      if(print) {
        return source.pipe(takeUntil(this.cancelProviderRequest$))
        }
      return source
    }
  } 

  takeFacilityHelper(print: boolean) {
    return (source: Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations>):
      Observable<KEngineResponseContainerFacilityResponseOfOrgAffiliations> => {
        if(print) {
          return source.pipe(takeUntil(this.cancelFacilityRequest$))
        }
        return source
      }
  }

  ngOnDestroy(): void {
    this.cancelProviderRequest$.next()
    this.cancelProviderRequest$.complete()
    this.cancelFacilityRequest$.next()
    this.cancelFacilityRequest$.complete();
  }
}
