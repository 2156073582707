import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultCardExpandTab2Component } from './search-result-card-expand-tab2.component';
// import { ButtonModule, SectionModule } from 'lo-bahamut';
// import { BahamutModule } from '../../../../bahamut.module';
import { CustomCarouselModule } from '../../../../_shared/custom-carousel/custom-carousel.module';
import { Button, Container } from "lo-bahamut";

@NgModule({
  declarations: [SearchResultCardExpandTab2Component],
  exports: [SearchResultCardExpandTab2Component],
  imports: [
    CommonModule,
    // ButtonModule,
    // SectionModule,
    // BahamutModule,
    Button,
    CustomCarouselModule,
    Container,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultCardExpandTab2Module {}
