import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss'],
})
export class CustomCarouselComponent implements OnInit, AfterViewInit {
  currentIndex: number = 0;
  blockWidth: any;
  @Output() notifyCurrentIndex: EventEmitter<any> = new EventEmitter();
  @Input() items: any[] = [];
  showDots: boolean = true;
  hideSlideCount: boolean = false;
  @ViewChild('carouselWindow', { static: true }) carouselWindow!: ElementRef;

  ngAfterViewInit() {
    // Get the width of a single block based on the first block element's offsetWidth
    this.blockWidth = this.carouselWindow.nativeElement.firstChild.offsetWidth;

    // Now you can use the blockWidth to set the scroll position
    this.updateCarouselPosition(this.blockWidth);
  }
  ngOnInit() {
    if (this.items.length == 1 || this.items.length > 10) {
      this.showDots = false;
      this.hideSlideCount = true;
    }
  }

  prev() {
    this.blockWidth = this.carouselWindow.nativeElement.firstChild.offsetWidth;
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.updateCarouselPosition(this.blockWidth);
      this.notifyCurrentIndex.emit(this.currentIndex);
    }
  }

  next() {
    this.blockWidth = this.carouselWindow.nativeElement.firstChild.offsetWidth;
    if (this.currentIndex <= this.items.length - 1) {
      this.currentIndex++;
      this.updateCarouselPosition(this.blockWidth);
      this.notifyCurrentIndex.emit(this.currentIndex);
    }
  }
  goToSlide(index: number): void {
    this.blockWidth = this.carouselWindow.nativeElement.firstChild.offsetWidth;
    this.currentIndex = index;
    this.updateCarouselPosition(this.blockWidth);
  }
  updateCarouselPosition(blockWidth: number) {
    if (this.carouselWindow && this.blockWidth) {
      this.carouselWindow.nativeElement.scroll({
        left: this.currentIndex * blockWidth,
        behavior: 'smooth',
      });
    }
  }
}
