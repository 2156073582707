<div class="slider-container">
  <input
    type="range"
    id="miles-range"
    [min]="minValue"
    [max]="maxValue"
    [value]="currentValue"
    [step]="useTicks ? tickInterval : defaultTickInterval"
    (input)="updateValue($event)"
    (click)="onSlide($event)"
  />
<!--  <span class="slider-value">{{ currentValue }}</span>-->
</div>
<datalist *ngIf="useTicks" class="ticks">
  <option *ngFor="let tick of ticks" [style.left]="tick + '%'">
    {{ tickValue(tick) }}
  </option>
</datalist>

<lo-dropdown *ngIf="distanceOptions.length > 0"
  nogm
  id="distance-option"
  variant="basic"
  label="Within distance (in miles)."
  [placeholder]="'Distance'"
  errormessage=""
  [options]="distanceOptions"
  (onChange)="onSelectChange($event)"
  #distanceOption
>
</lo-dropdown>
