import { Component, Input, OnInit } from '@angular/core';
import { ProviderResponseOfMasteredLocations } from '../../../../_core/api/models/provider-response-of-mastered-locations';
import { FacilityResponseOfMasteredLocations } from '../../../../_core/api/models/facility-response-of-mastered-locations';
import {
  SearchResultCardExpandInformationData,
  SearchResultCardExpandTab1AdapterService,
} from '../../../../_core/services/searchResultCard/search-result-card-expand-tab1-adapter.service';

@Component({
  selector: 'app-search-result-card-expand-tab1',
  templateUrl: './search-result-card-expand-tab1.component.html',
  styleUrls: ['./search-result-card-expand-tab1.component.scss'],
})
export class SearchResultCardExpandTab1Component implements OnInit {
  @Input() dataType!: 'practitioner' | 'facility';
  @Input() detailRoot!:
    | ProviderResponseOfMasteredLocations
    | FacilityResponseOfMasteredLocations;
  data?: SearchResultCardExpandInformationData;
  constructor(
    private searchResultCardExpandTab1AdapterService: SearchResultCardExpandTab1AdapterService
  ) {}

  ngOnInit() {
    if (this.dataType === 'practitioner') {
      const providerResponse = this
        .detailRoot as ProviderResponseOfMasteredLocations;
      if (providerResponse && providerResponse.providers) {
        const aggregateRootProvider = providerResponse.providers[0];
        this.data = this.searchResultCardExpandTab1AdapterService.adaptProvider(
          aggregateRootProvider
        );
      }
    } else if (this.dataType === 'facility') {
      const facilityResponse = this
        .detailRoot as FacilityResponseOfMasteredLocations;
      if (facilityResponse && facilityResponse.facilities) {
        const aggregateRootFacility = facilityResponse.facilities[0];
        this.data = this.searchResultCardExpandTab1AdapterService.adaptFacility(
          aggregateRootFacility
        );
      }
    }
  }
}
