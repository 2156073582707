import {
  AfterViewInit,
  Component, DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import {Dropdown} from "lo-bahamut";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() useTicks = false;
  @Input() tickInterval = 10;
  @Input() minValue = 5;
  @Input() maxValue = 100;
  @Input() currentValue: any = ['25'];
  @Input() selectedValue: any;
  @Output() valueChange = new EventEmitter<string[]>();

  @ViewChild('distanceOption') distanceOption!: Dropdown;

  defaultTickInterval = 5;
  ticks: number[] = [];
  distanceOptions =[
    {
      "value": "5",
      "description": "5"
    },
    {
      "value": "10",
      "description": "10"
    },
    {
      "value": "15",
      "description": "15"
    },
    {
      "value": "20",
      "description": "20"
    },
    {
      "value": "25",
      "description": "25"
    },
    {
      "value": "30",
      "description": "30"
    },
    {
      "value": "35",
      "description": "35"
    },
    {
      "value": "40",
      "description": "40"
    },
    {
      "value": "45",
      "description": "45"
    },
    {
      "value": "50",
      "description": "50"
    },
    {
      "value": "55",
      "description": "55"
    },
    {
      "value": "60",
      "description": "60"
    },
    {
      "value": "65",
      "description": "65"
    },
    {
      "value": "70",
      "description": "70"
    },
    {
      "value": "75",
      "description": "75"
    },
    {
      "value": "80",
      "description": "80"
    },
    {
      "value": "85",
      "description": "85"
    },
    {
      "value": "90",
      "description": "90"
    },
    {
      "value": "95",
      "description": "95"
    },
    {
      "value": "100",
      "description": "100"
    }
  ]

  constructor(public cdRef: ChangeDetectorRef){}

  ngOnInit() {
    this.generateTicks();
  }
  ngAfterViewInit(): void {
    // Set the currentValue property of the slider component after the view has been initialized
    this.distanceOption.data = this.currentValue;
    this.cdRef.detectChanges();
  }
  ngDoCheck(){
    // this.distanceOption.data = this.currentValue;
  }


  generateTicks() {
    if (this.useTicks) {
      const tickCount = (this.maxValue - this.minValue) / this.tickInterval;
      this.ticks = Array(tickCount + 1)
        .fill(0)
        .map((_, i) => (i * 100) / tickCount);
    }
  }

  tickValue(percentage: number) {
    return this.minValue + (percentage / 100) * (this.maxValue - this.minValue);
  }

  updateValue(value: any) {
    this.currentValue = (value.target as any).value;
    this.valueChange.emit(this.currentValue);
    this.onSlide(value)
  }

  updateData(value: string[]){
    this.distanceOption.data= value;
    this.distanceOptions.forEach((option: any) => {
      option['selected'] = (option.value === value[0]);
    });
  }
  onSlide(event: any){
    this.currentValue = (event.target as any).value;
    this.distanceOption.data= [this.currentValue];
    this.distanceOptions.forEach((option: any) => {
      option['selected'] = (option.value === this.currentValue[0]);
    });
    // this.distanceOption.optionClicked(this.currentValue);
  }
  onSelectChange(event: any) {
    this.currentValue = event;
    this.valueChange.emit(this.currentValue);
  }
}
