import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResultCardExpandTab1Component } from './search-result-card-expand-tab1.component';
// import {BahamutModule} from "../../../../bahamut.module";
import { Container } from "lo-bahamut";


@NgModule({
    declarations: [SearchResultCardExpandTab1Component],
    exports: [SearchResultCardExpandTab1Component],
    imports: [CommonModule,
      // BahamutModule
      Container,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchResultCardExpandTab1Module { }
