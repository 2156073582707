<div class="file-header">
  <ng-container *ngIf="CURRENT_TENNANT == 'mddoh' else convergentLogo">
    <img class="logo" src="../../../assets/maryland-brand.png" alt="Maryland Logo" width="130">
  </ng-container >
  <ng-template #convergentLogo>
    <img class="logo" src="../../../assets/Convergent_logo_orgblu.png" alt="Convergent Logo">
  </ng-template>
</div>
<div style="text-align: center;">
    <p>{{buildResultSummary()}}</p>
</div>
<div class="grid-container">
    <div *ngFor="let card of cardData" class="grid-item">
        <h3 style="margin: 0;">{{card!.extras.Name}}</h3>
        <p style="margin: 0;">{{card!.extras.StandardAddress!.LineOne}}</p>
        <p style="margin: 0;">{{card!.extras.StandardAddress!.LineTwo}}</p>
        <p style="margin: 0;">{{card!.list.Phone}}</p>
        <h4 style="margin: 0;">Specialty:</h4>
        <p style="margin: 0;">{{card!.extras.Specialty}}</p>
        <h4 style="margin: 0;">Distance:</h4>
        <p style="margin: 0;">{{card!.extras.MinDistance}} mile(s)</p>
    </div>
</div>
