import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomCarouselComponent } from './custom-carousel.component';
// import {BahamutModule} from "../../bahamut.module";



@NgModule({
    declarations: [
        CustomCarouselComponent
    ],
    exports: [
        CustomCarouselComponent
    ],
    imports: [
        CommonModule,
      // BahamutModule
    ]
})
export class CustomCarouselModule { }
