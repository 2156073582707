import { Injectable } from '@angular/core';
import { find, forEach } from 'lodash-es';
import { Practitioner } from '../../api/models/practitioner';
import { AggregateRootOfPractitionerPractitionerRolesGroupedByLocation } from '../../api/models/aggregate-root-of-practitioner-practitioner-roles-grouped-by-location';
import { PractitionerRolesGroupedByLocation } from '../../api/models/practitioner-roles-grouped-by-location';
import { AggregateRootOfFacilityOrganizationAffiliationsGroupedByLocation } from '../../api/models/aggregate-root-of-facility-organization-affiliations-grouped-by-location';
import { OrganizationAffiliationsGroupedByLocation } from '../../api/models/organization-affiliations-grouped-by-location';
import { Facility } from '../../api/models/facility';

export interface SearchResultCardExpandInformationData {
  list: {
    Specialties: string[] | undefined;
    Languages: string[] | undefined;
    Qualifications: string[] | undefined;
    Identifiers: string[] | undefined;
    Groups: string[] | undefined;
  };
}
@Injectable({
  providedIn: 'root',
})
export class SearchResultCardExpandTab1AdapterService {
  constructor() {}
  private getUniqueSpecialtiesProvider(
    data: PractitionerRolesGroupedByLocation[]
  ): string[] {
    let res = new Set<string>();
    forEach(data, (i) => {
      if (i) {
        forEach(i.specialty, (s) => {
          if (s.display) {
            res.add(s.display);
          }
        });
      }
    });
    return Array.from(res);
  }

  private getUniqueLanguagesProvider(data: Practitioner | undefined) {
    const res: string[] = [];
    if (data && data.communication) {
      forEach(data.communication, (c) => {
        if (c.display) {
          res.push(c.display);
        }
      });
    }
    return res;
  }

  private getUniqueQualificationsProvider(data: Practitioner | undefined): string[] {
    let res: string[] = [];
    if (data && data.qualification) {
      const trimmedArray = data.qualification
        .map(item => item.display?.trim())
        .filter((item): item is string => !!item); // Filter out undefined values
      res = Array.from(new Set(trimmedArray));
    }
    return res;
  }

  private getUniqueIdentifiersProvider(data: Practitioner | undefined) {
    const res: string[] = [];
    if (data && data.identifier) {
      forEach(data.identifier, (c) => {
        if (c.type == 'NPI' || c.type == 'LN') {
          res.push(`${c.type}: ${c.value}`);
        }
      });
    }
    return res;
  }

  private getUniqueGroupsProvider(
    data: PractitionerRolesGroupedByLocation[]
  ): string[] {
    let res = new Set<string>();
    forEach(data, (i) => {
      if (i) {
        forEach(i.providerGroup, (s) => {
          if (s.name) {
            res.add(s.name);
            if (s.alias) {
              res.add(s.alias);
            }
          }
        });
      }
    });
    return Array.from(res);
  }

  adaptProvider(
    data: AggregateRootOfPractitionerPractitionerRolesGroupedByLocation
  ): SearchResultCardExpandInformationData {
    const goldenRecord = find(data?.heads, { golden: true });
    return {
      list: {
        Specialties: this.getUniqueSpecialtiesProvider(data.relations!),
        Languages: this.getUniqueLanguagesProvider(goldenRecord),
        Qualifications: this.getUniqueQualificationsProvider(goldenRecord),
        Identifiers: this.getUniqueIdentifiersProvider(goldenRecord),
        Groups: this.getUniqueGroupsProvider(data.relations!),
      },
    };
  }

  private getUniqueSpecialtiesFacility(
    data: OrganizationAffiliationsGroupedByLocation[]
  ): string[] {
    let res = new Set<string>();
    forEach(data, (i) => {
      if (i) {
        const currentSpecialty = i.specialty;
        forEach(currentSpecialty, (c) => {
          if (c.display) {
            res.add(c.display);
          }
        });
      }
    });
    return Array.from(res);
  }

  private getUniqueLangugesFacility(
    data: OrganizationAffiliationsGroupedByLocation[]
  ): string[] {
    let res = new Set<string>();
    forEach(data, (i) => {
      if (i && i.location) {
        const currentLocation = i.location;
        const currentCommnication = currentLocation.communication;
        forEach(currentCommnication, (c) => {
          if (c.display) {
            res.add(c.display);
          }
        });
      }
    });
    return Array.from(res);
  }

  private getUniqueIdentifiersFacility(data: Facility | undefined): string[] {
    let res = new Set<string>();
    if (data) {
      const facilityIdentifier = data.identifier;
      forEach(facilityIdentifier, (i) => {
        if (i.type != 'TAX') {
          res.add(`${i.type}: ${i.value}`);
        }
      });
    }

    return Array.from(res);
  }
  private getUniqueGroupsFacility(
    data: OrganizationAffiliationsGroupedByLocation[]
  ): string[] {
    let res = new Set<string>();
    forEach(data, (i) => {
      if (i) {
        const currentGroup = i.providerGroup;
        forEach(currentGroup, (c) => {
          if (c.name) {
            res.add(c.name);
            if (c.alias) {
              res.add(c.alias);
            }
          }
        });
      }
    });
    return Array.from(res);
  }

  adaptFacility(
    data: AggregateRootOfFacilityOrganizationAffiliationsGroupedByLocation
  ): SearchResultCardExpandInformationData {
    const goldenRecord = find(data?.heads, { golden: true });
    return {
      list: {
        Specialties: this.getUniqueSpecialtiesFacility(data.relations!),
        Languages: this.getUniqueLangugesFacility(data.relations!),
        Qualifications: undefined,
        Identifiers: this.getUniqueIdentifiersFacility(goldenRecord),
        Groups: this.getUniqueGroupsFacility(data.relations!),
      },
    };
  }
}
