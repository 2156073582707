import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintSearchResultsComponent } from './print-search-results.component';
// import { BahamutModule } from 'src/app/bahamut.module';
import { SearchResultCardModule } from '../search-result-card/search-result-card.module';

@NgModule({
  declarations: [PrintSearchResultsComponent],
  imports: [CommonModule,
    // BahamutModule,
    SearchResultCardModule],
  exports: [PrintSearchResultsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrintSearchResultsModule { }
