import { Injectable } from '@angular/core';
import { PrintService } from '../api/services';
import { FacilityResponseOfMasteredLocations, PractitionerRolesGroupedByLocation, ProviderResponseOfMasteredLocations } from '../api/models';
import { CURRENT_TENNANT } from 'src/app/app.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintPdfService {

  constructor(private printService: PrintService) { }

  getPrintPdfForSingleProvider(practitionerData: ProviderResponseOfMasteredLocations): Observable<Blob> {
    return this.printService.getSingleProviderPrintPdf({
      body: practitionerData,
      "x-tenant-id": CURRENT_TENNANT
    }) as Observable<Blob>
  }

  getPrintPdfForSingleFacility(facilityData: FacilityResponseOfMasteredLocations): 
  Observable<Blob> {
    return this.printService.getSingleFacilityPrintPdf({
      body: facilityData,
      "x-tenant-id": CURRENT_TENNANT
    }) as Observable<Blob>
  }
}
