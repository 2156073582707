<app-custom-carousel
  #customCarouselSize
  (notifyCurrentIndex)="currentActive($event)"
  [items]="relationsGroupedByLocation"
>
  <lo-container
    nogm
    class="carousel-slide no-margin align-baseline"
    [ngClass]="{ active: i === currentIndex }"
  >
    <lo-container nogm col="12" class="location-box detail-tab-content flex-column">
      <div class="provider-facility-name flex flex-column">
        <div class="flex space-between">
          <h3>{{this.capitalizeEachWord(item.root.location?.name?.[0] ?? "")}}</h3>
          <!-- <span class="distance">Distance: 1.23 miles</span> -->
        </div>
        <div class="specialties-container" *ngIf="item.root.specialty">
          <span
            *ngFor="
              let specialty of searchResultCardExpandTab2Service.getUniqueSpecialties(
                item.root.specialty
              )
            "
          >
            {{ specialty }}
          </span>
        </div>
      </div>
      <lo-container nogm  class="no-margin">
        <ul class="provider-facility-address-list">
          <li class="location">
            <span class="material-symbols-outlined">location_on</span>
<!--            {{item.root.location!.address?.[0]?.text}}-->
            <div class="address-details">
              <div class="line">{{ item.root.location!.address?.[0]?.line?.join(', ') }}</div>
              <div class="state-city">{{ item.root.location!.address?.[0]?.city }}, {{ item.root.location!.address?.[0]?.state }}</div>
              <div class="pincode">{{ item.root.location!.address?.[0]?.postalCode }}</div>
            </div>
          </li>
          <li class="phone-num" *ngIf="item.telecomData.indicators.phonePresent">
            <span class="material-symbols-outlined">call</span>
            {{ getFormattedPhoneFax(item.telecomData.phone![0]) }}
          </li>
          <li class="website" *ngIf="item.telecomData.indicators.urlPresent">
            <span class="material-symbols-outlined">language</span>
            <a href="https://{{formatUrl(item.telecomData.url![0])}}" target="_blank">{{ formatUrl(item.telecomData.url![0]) }}</a>
          </li>
          <li class="fax" *ngIf="item.telecomData.indicators.faxPresent">
            <span class="material-symbols-outlined">fax</span>
            {{ getFormattedPhoneFax(item.telecomData.fax![0]) }}
          </li>
        </ul>
      </lo-container>
      <lo-container nogm  class="no-margin">
        <ul class="office-hours">
          <li
            *ngFor="
              let availableTimeItem of searchResultCardExpandTab2Service.getFormattedAvailableTime(
                item.root
              )
            "
          >
            <span>{{ availableTimeItem?.DayValue }}</span>
            {{ availableTimeItem?.TimeValues }}
          </li>
        </ul>
      </lo-container>
      <lo-container nogm  class="no-margin flex-wrap align-start insurance-gmap-block">
        <lo-container nogm col="6" class="insurance-block flex-column">
          <div class="language">
            <div class="heading">Languages</div>
            <span class="pills"
              *ngFor="let language of searchResultCardExpandTab2Service.getUniqueCommunication(
                            item.root.location!.communication!
                          )
                        "
            >
                        {{ language }}
                      </span>
          </div>
          <div class="aditional-info-block">
            <div class="heading">Additional Information</div>
            <ng-container *ngIf="item.root.acceptingPatients">
              <ng-container *ngIf="item.root.acceptingPatients![0]?.code == 'newpt'">
                <span class="pills">{{item.root.acceptingPatients![0]?.display}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="item.root.location?.accessibility![0]">
              <ng-container *ngFor="let accessibility of item.root.location?.accessibility">
                <span class="pills">{{accessibility.display}}</span>
              </ng-container>
            </ng-container>
          </div>
          <div class="insurance-carrier-block">
            <div class="bold">Insurances Accepted</div>
            <ul>
              <li
                *ngFor="
                  let insuranceCarrier of searchResultCardExpandTab2Service.getAllInsuranceCarriers(
                    item.root
                  )
                "
              >
                {{ insuranceCarrier }}
              </li>
            </ul>
          </div>
        </lo-container>
        <lo-container nogm col="6">
          <img
            class="googleMapStaticImage"
            [src]="searchResultCardExpandTab2Service.getStaticMapSrc(item.root)"
            (click)="
              searchResultCardExpandTab2Service.goToMapDirections(item.root)
            "
            title="Click for directions"
            aria-label="Google Maps Directions"
          />
        </lo-container>
      </lo-container>
    </lo-container>
  </lo-container>
</app-custom-carousel>
