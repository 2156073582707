<app-custom-carousel
  class="insurence-acceptence-detail-carousel"
  #customCarouselSize
  (notifyCurrentIndex)="currentActive($event)"
  [items]="cardData"
>
  <lo-container
    nogm
    class="carousel-slide no-margin align-baseline"
    *ngFor="let cardItem of cardData; let i = index"
    [ngClass]="{ active: i === currentIndex }"
  >
    <lo-container nogm col="12" class="flex-column align-baseline">
      <div class="provider-facility-name flex">
        <h3>{{ cardItem.name }}</h3>
        <span class="flex">Allergy and Immunity</span>
      </div>
      <lo-row>
        <ul class="insurance-carrier-block flex flex-wrap">
          <li *ngFor="let insurance of Object.keys(cardItem.insuranceMap)">
            <div class="heading">{{ insurance }}</div>
            <ol>
              <li *ngFor="let value of cardItem.insuranceMap[insurance]">
                {{ value }}
              </li>
            </ol>
          </li>
        </ul>
      </lo-row>
    </lo-container>
  </lo-container>
</app-custom-carousel>
