import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BahamutModule } from '../bahamut.module';

@NgModule({
  declarations: [],
  imports: [CommonModule,
    // BahamutModule
  ],
  exports: [],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
