import { Injectable } from '@angular/core';
import {PractitionerRolesGroupedByLocation} from "../../api/models/practitioner-roles-grouped-by-location";
import {forEach} from "lodash-es";
import {
  OrganizationAffiliationsGroupedByLocation
} from "../../api/models/organization-affiliations-grouped-by-location";


export interface Tab3Data  {
  name: string | undefined;
  insuranceMap: Record<string, string[]>;
}
@Injectable({
  providedIn: 'root'
})
export class SearchResultCardExpandTab3AdapterService {

  constructor() { }

  adaptProvider(
    data: PractitionerRolesGroupedByLocation[]
  ): Tab3Data[] {
    const proInsuranceDataArray: Tab3Data[] = [];

    forEach(data, (currLocation) => {
      if (currLocation.location && currLocation.location.name && currLocation.network) {
        const insuranceData: Tab3Data = {
          name: currLocation.location.name[0],
          insuranceMap: {},
        };

        const currentNetwork = currLocation.network;

        if (currentNetwork) {
          //now, start collecting network carriers + insurance plan displays
          //first, go through each network item
          forEach(currentNetwork, (n) => {
            const currentInsurance = n.insurance![0];
            const currentCarrier = currentInsurance.carrier;
            const currentPlan = currentInsurance.plan;

            if (currentCarrier && currentPlan && currentCarrier.name) {
              const currArrayValue = insuranceData.insuranceMap[currentCarrier.name];

              if (currArrayValue) {
                //check if currentCarrier display value is a key in insuranceMap
                insuranceData.insuranceMap[currentCarrier.name] = [...currArrayValue, currentPlan.name!];
              } else {
                //if it doesn't exist
                insuranceData.insuranceMap[currentCarrier.name] = [currentPlan.name!];
              }
            }
          });
        }

        proInsuranceDataArray.push(insuranceData);
      }
    });
    return proInsuranceDataArray;
  }


  adaptFacility(
    data: OrganizationAffiliationsGroupedByLocation[]
  ): Tab3Data[] {
    const faciInsuranceDataArray: Tab3Data[] = [];

    forEach(data, (currLocation) => {
      if (currLocation.location && currLocation.location.name && currLocation.network) {
        const insuranceData: Tab3Data = {
          name: currLocation.location.name[0],
          insuranceMap: {},
        };

        const currentNetwork = currLocation.network;

        if (currentNetwork) {
          //now, start collecting network carriers + insurance plan displays
          //first, go through each network item
          forEach(currentNetwork, (n) => {
            const currentInsurance = n.insurance![0];
            const currentCarrier = currentInsurance.carrier;
            const currentPlan = currentInsurance.plan;

            if (currentCarrier && currentPlan && currentCarrier.name) {
              const currArrayValue = insuranceData.insuranceMap[currentCarrier.name];

              if (currArrayValue) {
                //check if currentCarrier display value is a key in insuranceMap
                insuranceData.insuranceMap[currentCarrier.name] = [...currArrayValue, currentPlan.name!];
              } else {
                //if it doesn't exist
                insuranceData.insuranceMap[currentCarrier.name] = [currentPlan.name!];
              }
            }
          });
        }

        faciInsuranceDataArray.push(insuranceData);
      }
    });
    return faciInsuranceDataArray;
  }
}
