/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChangeRequest } from '../models/change-request';
import { ChangeRequestAuditTrailRoot } from '../models/change-request-audit-trail-root';

@Injectable({
  providedIn: 'root',
})
export class DataStewardshipService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation auditTrailQueryRequestHandler
   */
  static readonly AuditTrailQueryRequestHandlerPath = '/data-stewardship/audit-trails/{stableIdHash}';

  /**
   * Allow data stewards to query any of the edits made to the entity.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditTrailQueryRequestHandler()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditTrailQueryRequestHandler$Response(params: {

    /**
     * The stableId of which all the audit trails associated to be returned.
     */
    stableId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ChangeRequestAuditTrailRoot>>> {

    const rb = new RequestBuilder(this.rootUrl, DataStewardshipService.AuditTrailQueryRequestHandlerPath, 'get');
    if (params) {
      rb.path('stableId', params.stableId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ChangeRequestAuditTrailRoot>>;
      })
    );
  }

  /**
   * Allow data stewards to query any of the edits made to the entity.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditTrailQueryRequestHandler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditTrailQueryRequestHandler(params: {

    /**
     * The stableId of which all the audit trails associated to be returned.
     */
    stableId: string;
  },
  context?: HttpContext

): Observable<Array<ChangeRequestAuditTrailRoot>> {

    return this.auditTrailQueryRequestHandler$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ChangeRequestAuditTrailRoot>>) => r.body as Array<ChangeRequestAuditTrailRoot>)
    );
  }

  /**
   * Path part for operation postDataChangeRequestHandler
   */
  static readonly PostDataChangeRequestHandlerPath = '/data-stewardship/change-requests';

  /**
   * Allow data stewards to make modifications to the entities.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postDataChangeRequestHandler()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  postDataChangeRequestHandler$Response(params: {

    /**
     * Data change request
     */
    body: ChangeRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, DataStewardshipService.PostDataChangeRequestHandlerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json; charset=utf-8');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json; charset&#x3D;utf-8',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Allow data stewards to make modifications to the entities.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postDataChangeRequestHandler$Response()` instead.
   *
   * This method sends `application/json; charset=utf-8` and handles request body of type `application/json; charset=utf-8`.
   */
  postDataChangeRequestHandler(params: {

    /**
     * Data change request
     */
    body: ChangeRequest
  },
  context?: HttpContext

): Observable<{
}> {

    return this.postDataChangeRequestHandler$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
