import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchStartComponent } from './search-start.component';
// import { BahamutModule } from '../bahamut.module';
import { Dropdown, InputLo, Container, Button } from "lo-bahamut";
import { SharedModule } from '../_shared/shared.module';
import { CustomCarouselModule } from '../_shared/custom-carousel/custom-carousel.module';
import { ComingSoonModule } from '../coming-soon/coming-soon.module';

@NgModule({
  declarations: [SearchStartComponent],
  imports: [
    ComingSoonModule,
    CommonModule,
    // BahamutModule,
    SharedModule,
    CustomCarouselModule,
    Dropdown,
    InputLo,
    Container,
    Button
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SearchStartModule {}
